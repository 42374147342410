import { action } from 'mobx'

const updateFileUploadProgress = action(
  (store, blobId, progress, error_msg = null) => {
    const uploadedFile = store.uploadedFiles.find(
      (attachment) => attachment.file.id === blobId
    )

    if (!uploadedFile) {
      return
    }

    uploadedFile.progress = progress
    switch (progress) {
      case 100:
      case 'uploaded':
        uploadedFile.status = 'uploaded'
        break
      case 'validated':
        uploadedFile.status = 'validated'
        break
      case 'error':
        uploadedFile.status = 'error'
        uploadedFile.error_msg = error_msg
        break
      default:
        break
    }
  }
)

export { updateFileUploadProgress }

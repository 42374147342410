import React from 'react'

import DocumentDefault from './DocumentDefault'
import DocumentDOCX from './DocumentDOCX'
import DocumentJPG from './DocumentJPG'
import DocumentPDF from './DocumentPDF'
import DocumentPPT from './DocumentPPT'

interface FileIconProps {
  fileType: string
  isHovered: boolean
}

const FileIcon: React.FC<FileIconProps> = ({ fileType, isHovered }) => {
  const getIconComponent = () => {
    const fill = isHovered ? 'white' : '#FAFAFA'

    switch (fileType.toLowerCase()) {
      case 'application/pdf':
        return <DocumentPDF fill={fill} />
      case 'image/png':
      case 'image/jpeg':
      case 'image/jpg':
        return <DocumentJPG fill={fill} />
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'application/msword':
        return <DocumentDOCX fill={fill} />
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      case 'application/vnd.ms-powerpoint':
        return <DocumentPPT fill={fill} />
      default:
        return <DocumentDefault fill={fill} />
    }
  }

  return <div>{getIconComponent()}</div>
}

export default FileIcon

import PropTypes from 'prop-types'
import React from 'react'

import CancelButton from './CancelButton'
import SubmitButton from './SubmitButton'

const Step2 = ({
  message,
  inputValue,
  setInputValue,
  handleSubmit,
  handleCancel,
}) => (
  <div className="my-4">
    <span className={'text-md font-normal text-txt-default-default py-4'}>
      {' '}
      {message.content}{' '}
    </span>
    <div className="py-2">
      <textarea
        className="w-full p-2 border rounded-lg box-border"
        placeholder="Enter your research question here."
        rows="4"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
      />
    </div>
    <div className="flex justify-end space-x-2 mt-2">
      <CancelButton handleCancel={() => handleCancel(2)} />
      <SubmitButton
        disabled={inputValue.length === 0}
        handleSubmit={() => handleSubmit(2, { research_question: inputValue })}
      />
    </div>
  </div>
)

Step2.propTypes = {
  message: PropTypes.shape({
    content: PropTypes.string.isRequired,
  }),
  inputValue: PropTypes.string,
  setInputValue: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
}

export default Step2

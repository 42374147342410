import React from 'react'

const PaperclipIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M21.9211 10.893L12.4325 20.3816C10.2747 22.5395 6.77618 22.5395 4.61836 20.3816C2.46055 18.2238 2.46055 14.7253 4.61836 12.5675L14.107 3.07891C15.5455 1.64036 17.8778 1.64036 19.3164 3.07891C20.7549 4.51745 20.7549 6.84979 19.3164 8.28833L10.1999 17.4048C9.48062 18.1241 8.31445 18.1241 7.59517 17.4048C6.8759 16.6856 6.8759 15.5194 7.59517 14.8001L15.5954 6.79992"
      fill="none"
      stroke="black"
    />
    {/*<path*/}
    {/*    d="M18.029 9.00374L9.48925 17.5435C7.54722 19.4855 4.39856 19.4855 2.45652 17.5435C0.514492 15.6014 0.514492 12.4528 2.45652 10.5108L10.9963 1.97102C12.2909 0.676328 14.3901 0.676328 15.6847 1.97102C16.9794 3.26571 16.9794 5.36481 15.6847 6.6595L7.4799 14.8643C6.83256 15.5117 5.783 15.5117 5.13566 14.8643C4.48831 14.217 4.48831 13.1674 5.13566 12.5201L12.3358 5.31993"*/}
    {/*    stroke="#5133EA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>*/}
    {/*<path*/}
    {/*    d="M14.2448 7.22513L7.60275 13.8671C6.09228 15.3776 3.64332 15.3776 2.13285 13.8671C0.622382 12.3567 0.622383 9.90772 2.13285 8.39725L8.77487 1.75524C9.78185 0.748255 11.4145 0.748255 12.4215 1.75523C13.4284 2.76221 13.4284 4.39485 12.4215 5.40183L6.03992 11.7834C5.53643 12.2869 4.72011 12.2869 4.21662 11.7834C3.71313 11.2799 3.71313 10.4636 4.21662 9.96008L9.81675 4.35995"*/}
    {/*    stroke="#5133EA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>*/}
  </svg>
)

export default PaperclipIcon

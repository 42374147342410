import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { retryFailedMemo } from '../../../apis/chatApi'
import InfoIconWithTriangle from '../../assets/InfoIconWithTriangle'
import Accordion from '../../shared/Accordion'

const MemoErrorMessage = ({ message, store }) => {
  const MESSAGES = {
    RETRY: 'Apologies, we were unable to generate the memo. Please ',
    FAILED:
      'Apologies, we were unable to generate the memo. Rephrasing or changing the scope of the question may produce different results.',
    REJECTED:
      "We weren't able to find any applicable law for your query. Rephrasing or changing the scope of the question may produce different results.",
  }

  const ShowRetryOption = () => {
    const [retryLinkEnabled, setRetryLinkEnabled] = useState(
      message.instant_memo_retries > 0
    )

    const handleRetry = () => {
      setRetryLinkEnabled(false)
      retryFailedMemo(store, message.instant_memo_id)
    }

    return (
      <div className="flex items-center space-x-2">
        <InfoIconWithTriangle />
        <p className="font-normal">{MESSAGES.RETRY}</p>
        <span
          className={`cursor-pointer underline ${
            retryLinkEnabled
              ? 'text-txt-brand-default'
              : 'text-txt-disabled-default'
          }`}
          onClick={retryLinkEnabled ? handleRetry : undefined}
        >
          try again.
        </span>
      </div>
    )
  }

  const showHelpAccordian = () => {
    return (
      <Accordion
        title="How to write a good research question."
        body={
          <div>
            <div className="mt-2">
              <p className="mt-2 font-normal">
                For best results, research questions should be objective and
                encompass one question.{' '}
              </p>
              <p className="mt-2 font-medium">
                Example of a well-framed research question:
              </p>
              <p className="mt-2 italic font-normal">
                In what circumstances will a court grant a request to limit the
                discovery of electronically stored information to certain
                keyword search terms?{' '}
              </p>
            </div>
          </div>
        }
        open={false}
      />
    )
  }

  const showMessage = (description) => {
    return (
      <div>
        <div className="flex items-center">
          <InfoIconWithTriangle />
          <p className="font-normal ml-2">{description}</p>
        </div>
        {showHelpAccordian()}
      </div>
    )
  }

  return (
    <div className="bg-bgCol-warning-secondary border border-brd-warning-secondary rounded-[0px_16px_16px_16px] py-3 px-4 text-txt-default-default text-md">
      {message.message_type === 'instantMemoRetrySuggested' && (
        <ShowRetryOption />
      )}
      {message.message_type === 'instantMemoRetryForbidden' &&
        showMessage(MESSAGES.FAILED)}
      {message.message_type === 'instantMemoRejected' &&
        showMessage(MESSAGES.REJECTED)}
    </div>
  )
}

MemoErrorMessage.propTypes = {
  message: PropTypes.shape({
    instant_memo_id: PropTypes.string,
    instant_memo_retries: PropTypes.number,
    message_type: PropTypes.string,
  }).isRequired,
  store: PropTypes.object,
}

export default MemoErrorMessage

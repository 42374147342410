import React from 'react'

interface DocumentMagicIconProps {
  defaultFill?: string
  hoverFill?: string
  isHovered: boolean
}

const DocumentMagicIcon: React.FC<DocumentMagicIconProps> = ({
  defaultFill = '#FAFAFA',
  hoverFill = 'white',
  isHovered,
}) => {
  return (
    <svg
      width="80"
      height="120"
      viewBox="0 0 80 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M67.6671 101H12.1116C9.16473 101 5.08374 101.085 3 99.0009C0.916262 96.9172 1.00047 92.8362 1.00047 89.8894V12.1116C1.00047 9.16473 0.91627 5.08374 3.00001 3.00001C5.08375 0.91627 9.16473 1.00047 12.1116 1.00047H51.0005L78.7782 28.7782V89.8894C78.7782 92.8362 78.8624 96.9172 76.7787 99.0009C74.695 101.085 70.614 101 67.6671 101Z"
        fill={isHovered ? hoverFill : defaultFill}
        stroke="#F2F2F2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51 1V23.2222C51 24.6956 51.5853 26.1087 52.6272 27.1506C53.6691 28.1925 55.0821 28.7778 56.5556 28.7778H78.7778"
        stroke="#F2F2F2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.6051 68.0363C41.5369 67.772 41.3992 67.5309 41.2062 67.3379C41.0132 67.1449 40.7721 67.0072 40.5078 66.939L35.823 65.7309C35.7431 65.7083 35.6727 65.6601 35.6226 65.5938C35.5726 65.5275 35.5455 65.4467 35.5455 65.3636C35.5455 65.2806 35.5726 65.1997 35.6226 65.1334C35.6727 65.0672 35.7431 65.019 35.823 64.9963L40.5078 63.7875C40.772 63.7194 41.0131 63.5818 41.206 63.389C41.399 63.1961 41.5368 62.9551 41.6051 62.691L42.8132 58.0062C42.8356 57.9259 42.8837 57.8552 42.9501 57.8049C43.0165 57.7545 43.0975 57.7273 43.1808 57.7273C43.2642 57.7273 43.3452 57.7545 43.4116 57.8049C43.478 57.8552 43.5261 57.9259 43.5485 58.0062L44.7558 62.691C44.824 62.9552 44.9617 63.1964 45.1547 63.3894C45.3477 63.5824 45.5888 63.7201 45.8531 63.7883L50.5379 64.9956C50.6185 65.0178 50.6895 65.0658 50.7401 65.1323C50.7908 65.1988 50.8182 65.2801 50.8182 65.3636C50.8182 65.4472 50.7908 65.5285 50.7401 65.595C50.6895 65.6614 50.6185 65.7095 50.5379 65.7317L45.8531 66.939C45.5888 67.0072 45.3477 67.1449 45.1547 67.3379C44.9617 67.5309 44.824 67.772 44.7558 68.0363L43.5478 72.7211C43.5253 72.8013 43.4772 72.872 43.4108 72.9224C43.3444 72.9727 43.2634 73 43.1801 73C43.0967 73 43.0157 72.9727 42.9493 72.9224C42.8829 72.872 42.8348 72.8013 42.8124 72.7211L41.6051 68.0363Z"
        fill="#CECECE"
      />
      <path
        d="M32.4627 54.8909C32.4237 54.7399 32.345 54.6021 32.2347 54.4918C32.1244 54.3815 31.9866 54.3028 31.8356 54.2638L29.1586 53.5735C29.1129 53.5606 29.0727 53.5331 29.0441 53.4952C29.0155 53.4573 29 53.4111 29 53.3636C29 53.3162 29.0155 53.27 29.0441 53.2321C29.0727 53.1942 29.1129 53.1667 29.1586 53.1538L31.8356 52.463C31.9866 52.4241 32.1244 52.3454 32.2346 52.2352C32.3449 52.1251 32.4236 51.9873 32.4627 51.8364L33.153 49.1594C33.1658 49.1135 33.1933 49.0731 33.2312 49.0444C33.2692 49.0156 33.3155 49 33.3631 49C33.4107 49 33.457 49.0156 33.4949 49.0444C33.5329 49.0731 33.5603 49.1135 33.5732 49.1594L34.2631 51.8364C34.302 51.9874 34.3807 52.1252 34.491 52.2355C34.6013 52.3458 34.7391 52.4245 34.8901 52.4634L37.5671 53.1533C37.6131 53.166 37.6537 53.1935 37.6827 53.2315C37.7116 53.2694 37.7273 53.3159 37.7273 53.3636C37.7273 53.4114 37.7116 53.4578 37.6827 53.4958C37.6537 53.5338 37.6131 53.5613 37.5671 53.574L34.8901 54.2638C34.7391 54.3028 34.6013 54.3815 34.491 54.4918C34.3807 54.6021 34.302 54.7399 34.2631 54.8909L33.5727 57.5679C33.5599 57.6137 33.5324 57.6541 33.4945 57.6829C33.4566 57.7117 33.4103 57.7273 33.3626 57.7273C33.315 57.7273 33.2687 57.7117 33.2308 57.6829C33.1928 57.6541 33.1654 57.6137 33.1525 57.5679L32.4627 54.8909Z"
        fill="#CECECE"
      />
      <path
        d="M41.6051 68.0363C41.5369 67.772 41.3992 67.5309 41.2062 67.3379C41.0132 67.1449 40.7721 67.0072 40.5078 66.939L35.823 65.7309C35.7431 65.7083 35.6727 65.6601 35.6226 65.5938C35.5726 65.5275 35.5455 65.4467 35.5455 65.3636C35.5455 65.2806 35.5726 65.1997 35.6226 65.1334C35.6727 65.0672 35.7431 65.019 35.823 64.9963L40.5078 63.7875C40.772 63.7194 41.0131 63.5818 41.206 63.389C41.399 63.1961 41.5368 62.9551 41.6051 62.691L42.8132 58.0062C42.8356 57.9259 42.8837 57.8552 42.9501 57.8049C43.0165 57.7545 43.0975 57.7273 43.1808 57.7273C43.2642 57.7273 43.3452 57.7545 43.4116 57.8049C43.478 57.8552 43.5261 57.9259 43.5485 58.0062L44.7558 62.691C44.824 62.9552 44.9617 63.1964 45.1547 63.3894C45.3477 63.5824 45.5888 63.7201 45.8531 63.7883L50.5379 64.9956C50.6185 65.0178 50.6895 65.0658 50.7401 65.1323C50.7908 65.1988 50.8182 65.2801 50.8182 65.3636C50.8182 65.4472 50.7908 65.5285 50.7401 65.595C50.6895 65.6614 50.6185 65.7095 50.5379 65.7317L45.8531 66.939C45.5888 67.0072 45.3477 67.1449 45.1547 67.3379C44.9617 67.5309 44.824 67.772 44.7558 68.0363L43.5478 72.7211C43.5253 72.8013 43.4772 72.872 43.4108 72.9224C43.3444 72.9727 43.2634 73 43.1801 73C43.0967 73 43.0157 72.9727 42.9493 72.9224C42.8829 72.872 42.8348 72.8013 42.8124 72.7211L41.6051 68.0363Z"
        stroke="#CECECE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.4627 54.8909C32.4237 54.7399 32.345 54.6021 32.2347 54.4918C32.1244 54.3815 31.9866 54.3028 31.8356 54.2638L29.1586 53.5735C29.1129 53.5606 29.0727 53.5331 29.0441 53.4952C29.0155 53.4573 29 53.4111 29 53.3636C29 53.3162 29.0155 53.27 29.0441 53.2321C29.0727 53.1942 29.1129 53.1667 29.1586 53.1538L31.8356 52.463C31.9866 52.4241 32.1244 52.3454 32.2346 52.2352C32.3449 52.1251 32.4236 51.9873 32.4627 51.8364L33.153 49.1594C33.1658 49.1135 33.1933 49.0731 33.2312 49.0444C33.2692 49.0156 33.3155 49 33.3631 49C33.4107 49 33.457 49.0156 33.4949 49.0444C33.5329 49.0731 33.5603 49.1135 33.5732 49.1594L34.2631 51.8364C34.302 51.9874 34.3807 52.1252 34.491 52.2355C34.6013 52.3458 34.7391 52.4245 34.8901 52.4634L37.5671 53.1533C37.6131 53.166 37.6537 53.1935 37.6827 53.2315C37.7116 53.2694 37.7273 53.3159 37.7273 53.3636C37.7273 53.4114 37.7116 53.4578 37.6827 53.4958C37.6537 53.5338 37.6131 53.5613 37.5671 53.574L34.8901 54.2638C34.7391 54.3028 34.6013 54.3815 34.491 54.4918C34.3807 54.6021 34.302 54.7399 34.2631 54.8909L33.5727 57.5679C33.5599 57.6137 33.5324 57.6541 33.4945 57.6829C33.4566 57.7117 33.4103 57.7273 33.3626 57.7273C33.315 57.7273 33.2687 57.7117 33.2308 57.6829C33.1928 57.6541 33.1654 57.6137 33.1525 57.5679L32.4627 54.8909Z"
        stroke="#CECECE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DocumentMagicIcon

import PropTypes from 'prop-types'
import React from 'react'

import ActivityItemHeader from './ActivityItemHeader'
import FileUploadIcon from '../assets/FileUploadIcon'

const ActivityItemContainer = ({ activity, children, description }) => (
  <div
    key={activity.id}
    ref={activity.ref}
    className="mb-4 mt-4 p-2 hover:bg-bgCol-neutral-default rounded-lg"
  >
    <div className="flex items-start">
      {/* Activity icon */}
      <span className="w-5">
        <FileUploadIcon />
      </span>

      {/* Activity content */}
      <div className="ml-2">
        <ActivityItemHeader activity={activity} description={description} />
        {children}
      </div>
    </div>
  </div>
)

ActivityItemContainer.propTypes = {
  activity: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    activity_item: PropTypes.shape({
      description: PropTypes.string,
    }),
    ref: PropTypes.object,
  }).isRequired,
  children: PropTypes.node,
  description: PropTypes.string.isRequired,
}

export default ActivityItemContainer

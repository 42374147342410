const timezonedDatetime = (datetimeString, store, mode) => {
  const timeZone =
    store.user?.iana_timezone ||
    Intl.DateTimeFormat().resolvedOptions().timeZone
  let dateString

  switch (mode) {
    case 'fromISODateFormat': {
      const [year, month, day] = datetimeString.split('-')
      const parsedDate = new Date(Number(year), Number(month) - 1, Number(day))

      dateString = parsedDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
      break
    }
    case 'timeFormat': {
      dateString = new Date(datetimeString).toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true, // 12-hour clock with AM/PM
        timeZone: timeZone,
      })
      break
    }
    case 'toDateIsoFormat': {
      dateString = new Date(datetimeString).toLocaleDateString('en-CA', {
        timeZone: timeZone,
      })
      break
    }
    default:
      console.error('Invalid mode passed to the function')
  }

  return dateString
}

export default timezonedDatetime

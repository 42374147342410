import { runInAction } from 'mobx'

import { findMessage } from './messagesActions'

// Utility function to add highlight to an element
const addHighlight = (ref) => {
  if (ref && ref.current) {
    ref.current.classList.add(
      'bg-gradient-to-b',
      'from-[#F5F2FF]', // Starting color at the top (light purple)
      'to-[#FFFFFF]', // Ending color at the bottom
      'transition',
      'duration-500',
      'px-2',
      'py-2'
    )

    // Remove the gradient background after 5 seconds
    setTimeout(() => {
      removeHighlight(ref)
    }, 5000)
  }
}

// Utility function to remove highlight from an element
const removeHighlight = (ref) => {
  if (ref && ref.current) {
    ref.current.classList.remove(
      'bg-gradient-to-b',
      'from-[#F5F2FF]',
      'to-[#FFFFFF]',
      'px-2',
      'py-2'
    )
  }
}

// Utility function to scroll to an element
const scrollToElement = (ref) => {
  if (ref && ref.current) {
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }
}

const scrollAndHighlight = (store, mainRef, secondaryRef) => {
  // If there's a previously highlighted activity, message remove its highlight
  if (store.highlightedActivityRef) {
    removeHighlight(store.highlightedActivityRef)
  }
  if (store.highlightedMessageRef) {
    removeHighlight(store.highlightedMessageRef)
  }

  scrollToElement(mainRef)
  addHighlight(mainRef)

  // Highlight the secondary reference (activity or document)
  addHighlight(secondaryRef)

  // Update the store with the new highlighted references
  runInAction(() => {
    store.highlightedMessageRef = mainRef
    store.highlightedActivityRef = secondaryRef
  })
}

// Exported function for scrolling and highlighting messages
export const scrollAndHighlightMessage = (store, activity) => {
  const message = findMessage(
    store,
    store.selectedMatter.id,
    activity.chat_thread_message_id
  )
  scrollAndHighlight(store, message.ref, activity.ref)
}

// Exported function for scrolling and highlighting documents
export const scrollAndHighlightDocument = (store, document) => {
  const message = findMessage(
    store,
    store.selectedMatter.id,
    document.documentable.chat_thread_message_id
  )
  scrollAndHighlight(store, message.ref, document.ref)
}

import PropTypes from 'prop-types'
import React from 'react'

import { showModal } from '../../../actions/modalActions'
import { useStore } from '../../../useStore'
import MemoDocument from '../../assets/MemoDocument'

const DocSummaryMessage = ({ message }) => {
  const store = useStore()

  const handleClick = () => {
    showModal(store, {
      contentType: 'SummaryDocument',
      contentId: message.summary_document.id,
      data: message.summary_document,
    })
  }

  return (
    <div
      className="relative max-w-64 flex flex-col bg-white items-start rounded-lg cursor-pointer border border-brd-neutral-default transition-all overflow-hidden"
      onClick={handleClick}
    >
      <div className="flex items-center w-full p-3 rounded-md">
        <div className="p-2 bg-bgCol-neutral-secondary rounded-md flex items-center justify-center">
          <MemoDocument />
        </div>
        <div className="ml-2 min-w-0">
          <h3 className="text-sm font-semibold text-txt-default-default truncate">
            {message.summary_document.title}
          </h3>
          <p className="text-xs text-txt-default-secondary">Summary document</p>
        </div>
      </div>
    </div>
  )
}

DocSummaryMessage.propTypes = {
  message: PropTypes.shape({
    id: PropTypes.string.isRequired,
    matter_id: PropTypes.string.isRequired,
    summary_document: PropTypes.shape({
      title: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      summary: PropTypes.string.isRequired,
    }),
  }).isRequired,
}

export default DocSummaryMessage

import React from 'react'

import InfoIcon from '../../assets/InfoIcon'

const SummaryModalAiDisclaimer = () => {
  return (
    <div className="grid gap-2 bg-bgCol-neutral-default rounded-lg p-4">
      <div className="row-start-1 col-start-1 self-center">
        <InfoIcon />
      </div>
      <div className="row-start-1 col-start-2 self-center">
        <h2 className="font-medium">
          Understanding your AI-generated summary document.
        </h2>
      </div>
      <div className="row-start-2 col-start-2 text-txt-neutral-default text-sm">
        <p className="pb-2">
          This summary document was created using advanced AI, including a mix
          of Alexi&apos;s proprietary models and GPT models. To confirm
          accuracy, we recommend reviewing the original document.
        </p>
        <p className="pb-2">
          For any questions, reach out to{' '}
          <a className="text-txt-brand-default" href="mailto:support@alexi.com">
            support@alexi.com
          </a>
          .
        </p>
      </div>
    </div>
  )
}

export default SummaryModalAiDisclaimer

import { action } from 'mobx'

export const getMessageQueue = (store, matterId) => {
  return store.messageQueues[matterId]
}

export const initializeMessageQueue = action((store, matterId) => {
  if (!store.messageQueues[matterId]) {
    store.messageQueues[matterId] = []
  }
})

export const setMessageQueue = action((store, matterId, messageQueue) => {
  store.messageQueues[matterId] = messageQueue.sort(
    (a, b) => a.chunk_id - b.chunk_id
  )
})

export const addMessageToQueue = action((store, matterId, message) => {
  store.messageQueues[matterId].push(message)
  store.messageQueues[matterId].sort((a, b) => {
    return a.chunk_id - b.chunk_id
  })
})

export const getNextMessageFromQueue = action((store, matterId) => {
  return store.messageQueues[matterId].shift()
})

import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'

import ContentModal from './ContentModal'
import FirmDisabledModal from './FirmDisabledModal'
import FlashMessages from './FlashMessages'
import Header from './Header'
import Sidebar from './Sidebar'
import { setMatters } from '../actions/matterActions'
import StoreContext from '../StoreContext'
import { useStore } from '../useStore'

const ChatApp: React.FC = () => {
  const store = useStore()

  const [mattersLoaded, setMattersLoaded] = useState(false)

  useEffect(() => {
    setMatters(store).then(() => setMattersLoaded(true))
    //   eslint-disable-next-line react-hooks/exhaustive-deps -- this is correct
  }, [])

  return (
    <StoreContext.Provider value={store}>
      <div className="flex flex-col h-screen overflow-hidden min-w-[1024px] max-w-[1920px] min-h-[720px] mx-auto">
        <Header />
        <div className="flex flex-1 h-screen overflow-y-auto">
          <Sidebar />
          {mattersLoaded && <Outlet />}
        </div>
        <ContentModal />
        <FlashMessages />
      </div>

      {/* FirmDisabledModal overlay */}
      {store.user && store.user.enterprise.is_firm_disabled && (
        <FirmDisabledModal store={store} />
      )}
    </StoreContext.Provider>
  )
}

export default ChatApp

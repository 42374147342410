import Cookies from 'js-cookie'
import { action, runInAction } from 'mobx'

import { setChatResponseLoading } from './chatResponseLoadingActions'
import { initializeLastProcessedIds } from './lastProcessedIdsStoreActions'
import { initializeMessageQueue } from './messageQueuesStoreActions'
import { initializeMessages, setMessages } from './messagesActions'
import { fetchMatters } from '../apis/mattersApi'
import { connectToMatterChannel } from '../channels/matter_channel'
import { removeStoredMessage } from '../LocalStorageUtil'
import { rollbarConfig } from '../rollbarConfig'

const setSelectedMatter = action((store, matter) => {
  if (store.selectedMatter?.id) {
    setChatResponseLoading(store, store.selectedMatter.id, false)
  }

  store.selectedMatter = matter

  if (store.selectedMatter?.id) {
    const matterId = store.selectedMatter.id

    connectToMatterChannel(store, matterId)

    initializeLastProcessedIds(store, matterId)
    initializeMessageQueue(store, matterId)
    initializeMessages(store, matterId)

    Cookies.set('last_selected_matter', matter?.id, {
      expires: 1 / 48,
    })
  } else {
    Cookies.remove('last_selected_matter')
  }

  if (store.inputRef) {
    store.inputRef.focus()
  }
})

const setNewMatter = action((store, matter) => {
  try {
    removeStoredMessage('newMatter')
    store.selectedMatter = matter
    connectToMatterChannel(store, matter?.id)
    store.matters.unshift(matter)
    setMessages(store, matter?.id, [])
  } catch (error) {
    console.error('Error setting new matter', error)
  }
})

const setMatters = action(async (store) => {
  try {
    const allMatters = await fetchMatters(store)

    // Filter matters directly and assign the result to store.matters
    runInAction(() => {
      store.matters = allMatters.filter(
        (matter) => !matter.is_scheduled_for_deletion
      )
    })
  } catch (error) {
    rollbarConfig(store)?.error(error)
  }
})

export { setSelectedMatter, setMatters, setNewMatter }

import PropTypes from 'prop-types'
import React from 'react'

const WorkflowMessageIcon = ({ stroke = '#1DA46D' }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.20796 19.8956C10.2162 20.9258 12.5263 21.2048 14.722 20.6824C16.9177 20.16 18.8547 18.8706 20.1838 17.0464C21.5129 15.2223 22.1468 12.9834 21.9713 10.7333C21.7958 8.48308 20.8223 6.36959 19.2264 4.77364C17.6304 3.1777 15.5169 2.20424 13.2667 2.0287C11.0166 1.85316 8.77769 2.48707 6.95355 3.8162C5.12941 5.14534 3.83997 7.08229 3.31758 9.27801C2.7952 11.4737 3.07423 13.7838 4.10439 15.792L2 22L8.20796 19.8956Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

WorkflowMessageIcon.propTypes = {
  stroke: PropTypes.string,
}

export default WorkflowMessageIcon

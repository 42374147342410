import axios from 'axios'
import { action, runInAction } from 'mobx'

import { extendSessionTimeout } from './sessionActions'
import type { Store } from '../Store'

interface showModalOptions {
  contentType: 'InstantMemo' | 'SummaryDocument' | 'EnterpriseAttachment'
  contentId: string
  data: object | null
}

// Exported functions

const showModal = action(
  async (store: Store, { contentType, contentId, data }: showModalOptions) => {
    if (store.modal.isOpen) {
      return
    }

    store.modal.isOpen = true

    // If we successfully loaded up the needed data previously, return
    if (
      store.modal.contentType === contentType &&
      store.modal.contentId === contentId &&
      store.modal.errorMessage === null
    ) {
      return
    }

    resetModal(store)
    store.modal.contentType = contentType
    store.modal.contentId = contentId

    switch (contentType) {
      case 'InstantMemo':
        store.modal.data = {}
        store.modal.isLoading = true
        try {
          const instant_memo = await fetchMemoInfo(store, contentId)
          const instant_memo_html = await fetchMemoHtml(store, contentId)
          runInAction(() => {
            store.modal.data = {
              instant_memo,
              instant_memo_html,
            }
            store.modal.isLoading = false
          })
        } catch (error: any) {
          runInAction(() => {
            store.modal.errorMessage = error.message
            store.modal.isLoading = false
          })
        }

        break
      case 'SummaryDocument':
        store.modal.data = data || {}
        break
      default:
        resetAndCloseModal(store)
    }
  }
)

const hideModal = action((store: Store) => {
  store.modal.isLoading = false
  store.modal.isOpen = false
})

const resetModal = action((store: Store) => {
  store.modal.isLoading = false
  store.modal.contentType = null
  store.modal.contentId = null
  store.modal.errorMessage = null
  store.modal.data = {}
})

const resetAndCloseModal = action((store: Store) => {
  store.modal.isOpen = false
  store.modal.isLoading = false
  store.modal.contentType = null
  store.modal.contentId = null
  store.modal.errorMessage = null
  store.modal.data = {}
})

// Request helpers

const fetchMemoInfo = async (store: Store, instantMemoId: string) => {
  extendSessionTimeout(store)
  const response = await axios.get(
    `/api/client/v1/instant_memos/${instantMemoId}`,
    {
      withCredentials: true,
    }
  )
  if (response.status !== 200) {
    throw new Error('Failed to fetch memo info')
  }

  return response.data.info
}

const fetchMemoHtml = async (store: Store, instantMemoId: string) => {
  extendSessionTimeout(store)
  const response = await axios.get(
    `/api/client/v1/instant_memos/${instantMemoId}/display_memo`,
    { withCredentials: true }
  )
  if (response.status !== 200) {
    throw new Error('Failed to fetch memo')
  }

  const parser = new DOMParser()
  const doc = parser.parseFromString(response.data.html, 'text/html')
  const links = doc.querySelectorAll('a')

  links.forEach((link) => {
    link.setAttribute('target', '_blank')
  })

  return doc.documentElement.innerHTML
}

export { showModal, hideModal }

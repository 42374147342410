import PropTypes from 'prop-types'
import React, { useState } from 'react'

const Tooltip = ({
  children,
  tooltipContents,
  position = 'below',
  additionalClasses = '',
}) => {
  const [visible, setVisible] = useState(false)

  const showTooltip = () => setVisible(true)
  const hideTooltip = () => setVisible(false)

  return (
    <div
      className="relative flex items-center"
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      {children}
      {visible && (
        <div
          className={`absolute z-10 p-2 text-xs font-normal text-white text-left bg-bgCol-utilities-secondary rounded-md shadow-lg transform 
            ${position === 'above' ? 'bottom-full mb-2 left-1/2 -translate-x-1/2' : ''}
            ${position === 'below' ? 'top-full mt-2 left-1/2 -translate-x-1/2' : ''}
            ${position === 'left' ? 'right-full mr-2 top-1/2 -translate-y-1/2' : ''}
            break-words ${additionalClasses}`}
        >
          {tooltipContents}
          <div
            className={`absolute w-[10px] h-[10px] bg-bgCol-utilities-secondary transform rotate-45 
              ${position === 'above' ? 'top-full left-1/2 -translate-x-1/2 -translate-y-1/2' : ''}
              ${position === 'below' ? 'bottom-full left-1/2 -translate-x-1/2 translate-y-1/2' : ''}
              ${position === 'left' ? 'left-full top-1/2 -translate-y-1/2 -translate-x-1/2' : ''}
            `}
          />
        </div>
      )}
    </div>
  )
}

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  tooltipContents: PropTypes.node.isRequired,
  position: PropTypes.oneOf(['above', 'below', 'left']),
  additionalClasses: PropTypes.string,
}

export default Tooltip

import { action } from 'mobx'

import { setInputValue } from './InputValueActions'

const resetInputMessage = action((store) => {
  setInputValue(store, '')
})

const resetInputFile = action((store) => {
  store.uploadedFiles = []
})

const resetInput = action((store) => {
  resetInputMessage(store)
  resetInputFile(store)
})

export { resetInput, resetInputMessage, resetInputFile }

import { action } from 'mobx'

export const initializeLastProcessedIds = action((store, matterId) => {
  if (!store.lastProcessedIds[matterId]) {
    store.lastProcessedIds[matterId] = 0
  }
})

export const getLastProcessedId = (store, matterId) => {
  return store.lastProcessedIds[matterId]
}

export const setLastProcessedIds = action(
  (store, matterId, lastProcessedId) => {
    store.lastProcessedIds[matterId] = lastProcessedId
  }
)

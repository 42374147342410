import { runInAction } from 'mobx'

import consumer from './consumer'

export const createDocumentChannelSubscription = (store, matterId) => {
  return consumer.subscriptions.create(
    { channel: 'DocumentChannel', matter_id: matterId },
    {
      received(data) {
        runInAction(() => {
          const documentIndex = store.documents.findIndex(
            (doc) => doc.id === data.id
          )

          if (documentIndex !== -1) {
            store.documents[documentIndex] = data
          } else {
            store.documents.push(data)
          }
        })
      },
    }
  )
}

import React from 'react'

interface DocumentPDFProps {
  fill?: string
}

const DocumentPDF: React.FC<DocumentPDFProps> = ({ fill = '#FAFAFA' }) => (
  <svg
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M86.6671 110H31.1116C28.1647 110 24.0837 110.085 22 108.001C19.9163 105.917 20.0005 101.836 20.0005 98.8894V21.1116C20.0005 18.1647 19.9163 14.0837 22 12C24.0837 9.91627 28.1647 10.0005 31.1116 10.0005H70.0005L97.7782 37.7782V98.8894C97.7782 101.836 97.8624 105.917 95.7787 108.001C93.695 110.085 89.614 110 86.6671 110Z"
      fill={fill}
      stroke="#F2F2F2"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M70 10V32.2222C70 33.6956 70.5853 35.1087 71.6272 36.1506C72.6691 37.1925 74.0821 37.7778 75.5556 37.7778H97.7778"
      stroke="#F2F2F2"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g opacity="0.6">
      <path
        d="M51.9656 67.48C51.9656 68.2933 51.779 69.04 51.4056 69.72C51.0323 70.3866 50.459 70.9266 49.6856 71.34C48.9123 71.7533 47.9523 71.96 46.8056 71.96H44.6856V77H41.2656V62.96H46.8056C47.9256 62.96 48.8723 63.1533 49.6456 63.54C50.419 63.9266 50.999 64.46 51.3856 65.14C51.7723 65.82 51.9656 66.6 51.9656 67.48ZM46.5456 69.24C47.199 69.24 47.6856 69.0866 48.0056 68.78C48.3256 68.4733 48.4856 68.04 48.4856 67.48C48.4856 66.92 48.3256 66.4866 48.0056 66.18C47.6856 65.8733 47.199 65.72 46.5456 65.72H44.6856V69.24H46.5456Z"
        fill="#CECECE"
      />
      <path
        d="M59.0061 62.96C60.4861 62.96 61.7794 63.2533 62.8861 63.84C63.9928 64.4266 64.8461 65.2533 65.4461 66.32C66.0594 67.3733 66.3661 68.5933 66.3661 69.98C66.3661 71.3533 66.0594 72.5733 65.4461 73.64C64.8461 74.7066 63.9861 75.5333 62.8661 76.12C61.7594 76.7066 60.4728 77 59.0061 77H53.7461V62.96H59.0061ZM58.7861 74.04C60.0794 74.04 61.0861 73.6866 61.8061 72.98C62.5261 72.2733 62.8861 71.2733 62.8861 69.98C62.8861 68.6866 62.5261 67.68 61.8061 66.96C61.0861 66.24 60.0794 65.88 58.7861 65.88H57.1661V74.04H58.7861Z"
        fill="#CECECE"
      />
      <path
        d="M77.4173 62.96V65.7H71.6973V68.66H75.9773V71.32H71.6973V77H68.2773V62.96H77.4173Z"
        fill="#CECECE"
      />
    </g>
  </svg>
)

export default DocumentPDF

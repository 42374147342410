import axios from 'axios'

import { extendSessionTimeout } from '../actions/sessionActions'
import { rollbarConfig } from '../rollbarConfig'

async function getPresignedUrlAndMetadata({
  filename,
  byte_size,
  checksum,
  content_type,
  store,
  matter_id,
}) {
  extendSessionTimeout(store)

  try {
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content')
    const headers = {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken,
    }

    const response = await axios.post(
      '/api/client/v1/file_uploads',
      {
        blob: {
          filename,
          byte_size,
          checksum,
          content_type,
        },
        matter_id: matter_id,
      },
      {
        headers: headers,
      }
    )

    return response.data // Assuming your Rails backend returns { direct_upload: { ... }, blob: { ... } }
  } catch (error) {
    rollbarConfig(store)?.error(error)
    throw error
  }
}

const fetchFiles = async (store) => {
  extendSessionTimeout(store)

  try {
    const thread_id = store.selectedMatter.thread_id
    const response = await axios.get(
      `/api/client/v1/documents?thread_id=${thread_id}`
    )

    if (response.status === 200) {
      return response.data
    } else {
      return []
    }
  } catch (error) {
    rollbarConfig(store)?.error(error)
    throw error
  }
}

const bytesToMB = (bytes) => {
  const MB = 1024 * 1024
  return (bytes / MB).toFixed(2)
}

const validateFile = async (
  store,
  e_attachment_id,
  blob_id,
  file_size_bytes
) => {
  extendSessionTimeout(store)

  if (bytesToMB(file_size_bytes) > 120) {
    return { error_msg: 'File size exceeds 120MB' }
  }

  const url = `/api/client/v1/file_uploads/validate_file?e_attachment_id=${e_attachment_id}&blob_id=${blob_id}`

  try {
    const { data, status } = await axios.get(url)

    if (status === 200) {
      return data
    }

    return { error_msg: data?.error_msg || 'Upload failed' }
  } catch (error) {
    const errorMsg = error?.response?.data?.error_msg || 'Upload failed'

    rollbarConfig(store)?.error(error)

    return { error_msg: errorMsg }
  }
}

const downloadFile = async (store, doc) => {
  extendSessionTimeout(store)

  try {
    const thread_id = store.selectedMatter.thread_id

    const response = await axios.get(
      `/api/client/v1/enterprise_attachments/${doc.documentable.id}/download`,
      {
        params: {
          thread_id: thread_id,
          matter_id: store.selectedMatter.id,
        },
        headers: {
          Accept: 'application/json',
        },
        responseType: 'json',
      }
    )

    if (response.status === 200) {
      // Api call to download relies on the disposition attachment header, returns a
      // downloadable S3 Url. Adding it to window.location.href will download the file
      window.location.href = response.data
    }
  } catch (error) {
    rollbarConfig(store)?.error(error)
    throw error
  }
}

const deleteFile = async (store, doc) => {
  extendSessionTimeout(store)

  try {
    const thread_id = store.selectedMatter.thread_id
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content')
    const headers = {
      'X-CSRF-Token': csrfToken,
    }

    return await axios.delete(
      `/api/client/v1/enterprise_attachments/${doc.documentable.id}/delete_file`,
      {
        params: {
          thread_id: thread_id,
          matter_id: store.selectedMatter.id,
        },
        headers: headers,
      }
    )
  } catch (error) {
    rollbarConfig(store)?.error(error)
    throw error
  }
}

export {
  getPresignedUrlAndMetadata,
  fetchFiles,
  deleteFile,
  downloadFile,
  validateFile,
}

import React from 'react'

import EmptyActivity from './EmptyActivity'
import WorkflowMemoIcon from '../assets/WorkflowMemoIcon'
import WorkflowMessageIcon from '../assets/WorkflowMessageIcon'
import WorkflowUploadIcon from '../assets/WorkflowUploadIcon'

const EmptyActivitiesPlaceholder = () => {
  return (
    <>
      <div className="mt-8 mb-4 text-sm text-txt-default-secondary text-center">
        <p>
          Your work activities will be tracked here once you initiate your first
          conversation
        </p>
      </div>
      <EmptyActivity Icon={WorkflowMemoIcon} />
      <EmptyActivity Icon={WorkflowMessageIcon} />
      <EmptyActivity Icon={WorkflowUploadIcon} />
    </>
  )
}

export default EmptyActivitiesPlaceholder

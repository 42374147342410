import React from 'react'

const WarningIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="WarningIcon">
      <path
        id="Vector"
        d="M8.00701 6.20637V9.00414M8.00701 11.8019H8.014M14.8126 12.5014L9.21705 2.70916C9.09504 2.49388 8.91811 2.31481 8.7043 2.19022C8.4905 2.06564 8.24747 2 8.00002 2C7.75256 2 7.50954 2.06564 7.29573 2.19022C7.08193 2.31481 6.90499 2.49388 6.78299 2.70916L1.18745 12.5014C1.06412 12.7149 0.999457 12.9573 1 13.204C1.00055 13.4506 1.06629 13.6927 1.19056 13.9057C1.31483 14.1187 1.49321 14.2951 1.70762 14.417C1.92203 14.5389 2.16485 14.6019 2.41147 14.5997H13.6025C13.848 14.5994 14.089 14.5346 14.3015 14.4117C14.5139 14.2888 14.6903 14.1122 14.8129 13.8996C14.9356 13.687 15.0001 13.4459 15 13.2004C14.9999 12.955 14.9353 12.7139 14.8126 12.5014Z"
        stroke="#FC9D05"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)

export default WarningIcon

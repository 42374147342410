import { observer } from 'mobx-react-lite'
import React, { useState, useEffect } from 'react'

import { updateMatter } from '../../apis/mattersApi'
import { useStore } from '../../useStore'
import ChevronDownIcon from '../assets/ChevronDownIcon'
import MatterDetailsForm from '../shared/MatterDetailsForm'
import Tooltip from '../shared/Tooltip'

const MatterHeader = observer(({ matter, setIsSettingsOpen }) => {
  const store = useStore()

  const [isEditing, setIsEditing] = useState(false)
  const [matterName, setMatterName] = useState(matter.title || '')
  const [isPopupOpen, setIsPopupOpen] = useState(false)

  useEffect(() => {
    setMatterName(matter.title || '')
  }, [matter.title])

  const handleTitleClick = () => {
    setIsEditing(true)
  }

  const saveMatter = async () => {
    if (matter?.id) {
      const updatedMatter = { ...matter, assigned_name: matterName }
      await updateMatter(store, updatedMatter)
    }
  }

  const handleTitleChange = (e) => {
    setMatterName(e.target.value)
  }

  const handleBlur = () => {
    setIsEditing(false)
    saveMatter()
  }

  const openPopup = () => {
    setIsPopupOpen(true)
  }

  const closePopup = () => {
    setIsPopupOpen(false)
  }

  const truncatedMatterName =
    matterName.length > 80 ? `${matterName.substring(0, 80)}...` : matterName

  return (
    <div>
      <div className="flex items-center">
        {isEditing ? (
          <div className="text-txt-brand-default">
            <input
              type="text"
              value={matterName}
              onChange={handleTitleChange}
              onBlur={handleBlur}
              autoFocus
              className="text-lg font-semibold mr-2 focus:outline-none focus:border-none bg-bgCol-brand-secondary rounded-lg px-1"
              style={{
                width: `${matterName.length <= 1 ? '25px' : `${matterName.length}ch`}`,
                maxWidth: '450px',
              }}
            />
          </div>
        ) : (
          <div className="flex items-center hover:bg-bgCol-brand-secondaryHover rounded-lg transition-colors duration-200">
            <h2
              className="text-lg font-semibold mr-2 truncate"
              onClick={handleTitleClick}
              style={{ maxWidth: '100%' }}
              title={matterName}
            >
              {truncatedMatterName}
            </h2>
          </div>
        )}
        <Tooltip tooltipContents="View matter details">
          <button className="ml-0.5" onClick={() => setIsSettingsOpen(true)}>
            <ChevronDownIcon stroke={'black'} />
          </button>
        </Tooltip>
      </div>
      {matter.file_number ? (
        <p className="text-sm text-txt-neutral-secondary">
          {matter.file_number}
        </p>
      ) : (
        <button onClick={openPopup} className="text-xs text-txt-brand-default">
          Set file number
        </button>
      )}
      {isPopupOpen && (
        <div className="modal">
          <MatterDetailsForm matter={matter} onClose={closePopup} />
        </div>
      )}
    </div>
  )
})

export default MatterHeader

/* eslint-disable consistent-return */
import axios from 'axios'
import CryptoJS from 'crypto-js'

import addFile from '../../actions/AddFile'
import addFlash from '../../actions/AddFlash'
import { updateFileUploadProgress } from '../../actions/fileUploadActions'
import { getPresignedUrlAndMetadata, validateFile } from '../../apis/filesApi'
import { rollbarConfig } from '../../rollbarConfig'
import GetIconForContentType from '../main_chat/GetIconForContentType'

const MAX_FILE_COUNT = 10
const MAX_FILE_SIZE = 80 * 1024 * 1024 // 80 MB
const ALLOWED_FILE_TYPES = [
  'application/msword', // doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
  'application/vnd.ms-powerpoint', // ppt
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', // pptx
  'application/pdf', // pdf
  'image/jpeg', // jpg, jpeg
  'image/png', // png
  'image/gif', // gif
  'image/webp', // webp
]

async function handleFilesUpload(store, alreadyAcceptedAmount, acceptedFiles) {
  const validFiles = []

  for (const file of acceptedFiles) {
    if (validFiles.length + alreadyAcceptedAmount >= MAX_FILE_COUNT) {
      addFlash(
        store,
        'notice',
        `Maximum number of documents per upload reached. You can submit the remaining files in a new request.`
      )
      break
    }

    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      addFlash(
        store,
        'error',
        'This document is no supported. Check our list of supported documents.'
      )
      continue
    }

    validFiles.push(file)
  }

  try {
    const uploadPromises = validFiles.map(async (acceptedFile) => {
      const reader = new FileReader()
      const matterId = store.selectedMatter?.id

      return new Promise((resolve, reject) => {
        reader.onload = async (e) => {
          const wordArray = CryptoJS.lib.WordArray.create(e.target.result)
          const hash = CryptoJS.MD5(wordArray)
          const checksum = hash.toString(CryptoJS.enc.Base64) // Base64 checksum

          try {
            const { direct_upload_url, filename, key, file, content_type, id } =
              await getPresignedUrlAndMetadata({
                filename: acceptedFile.name,
                byte_size: acceptedFile.size,
                checksum,
                content_type: acceptedFile.type,
                store,
                matter_id: matterId,
              })
            addFile(store, {
              file: file,
              key: key,
              file_name: filename,
              icon_for_content_type: GetIconForContentType(content_type),
              content_type: content_type,
              e_attachment_id: id,
            })

            const response = await axios.put(direct_upload_url, acceptedFile, {
              headers: {
                'Content-MD5': checksum,
                'Content-Type': content_type,
              },
              onUploadProgress: (progressEvent) => {
                const progress = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                )
                updateFileUploadProgress(store, file.id, progress)
              },
            })

            if (response.status === 200) {
              updateFileUploadProgress(store, file.id, 'uploaded')

              const validationResult = await validateFile(
                store,
                id,
                file.id,
                file.byte_size
              )

              if (!validationResult.error_msg) {
                updateFileUploadProgress(store, file.id, 'validated')
              } else {
                updateFileUploadProgress(
                  store,
                  file.id,
                  'error',
                  validationResult.error_msg
                )
              }
            } else {
              updateFileUploadProgress(store, file.id, 'error', 'Upload failed')
            }

            resolve(response) // Resolve with the successful response
          } catch (error) {
            rollbarConfig(store)?.error(error)
            reject(error) // Reject the promise
          }
        }
        reader.readAsArrayBuffer(acceptedFile)
      })
    })

    await Promise.all(uploadPromises)
  } catch (error) {
    rollbarConfig(store)?.error(error)
  }
}

export { handleFilesUpload, MAX_FILE_SIZE, ALLOWED_FILE_TYPES }

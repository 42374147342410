import PropTypes from 'prop-types'
import React from 'react'

function MemoPopoverAiTooltip({ scrollToInfoBox }) {
  return (
    <div className="w-72 text-left">
      <p className="pb-2">This memo was created using advanced AI</p>
      <a
        className="text-txt-brand-secondary font-medium"
        href="#"
        onClick={scrollToInfoBox}
      >
        Read more
      </a>
    </div>
  )
}

export default MemoPopoverAiTooltip

MemoPopoverAiTooltip.propTypes = {
  scrollToInfoBox: PropTypes.func.isRequired,
}

import React from 'react'

const MemoDocument = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icon">
      <g id="Group 1000003815">
        <g id="Group">
          <path
            id="Vector"
            d="M17.6666 22.9999H5.44452C4.79622 22.9999 3.89841 23.0184 3.44 22.56C2.98158 22.1016 3.0001 21.2038 3.0001 20.5555V3.44452C3.0001 2.79622 2.98158 1.89842 3.44 1.44C3.89842 0.98158 4.79622 1.0001 5.44452 1.0001H14L20.1111 7.11116V20.5555C20.1111 21.2038 20.1296 22.1016 19.6712 22.56C19.2127 23.0184 18.3149 22.9999 17.6666 22.9999Z"
            stroke="#5133EA"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M14 1V5.88884C14 6.21299 14.1288 6.52387 14.358 6.75308C14.5872 6.98228 14.8981 7.11105 15.2222 7.11105H20.1111"
            stroke="#5133EA"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g id="Vector_3">
          <path
            d="M12.1996 15.9318C12.1715 15.8217 12.1147 15.7212 12.0351 15.6408C11.9555 15.5604 11.856 15.503 11.747 15.4746L9.81449 14.9712C9.78152 14.9618 9.7525 14.9417 9.73184 14.9141C9.71118 14.8865 9.7 14.8528 9.7 14.8182C9.7 14.7836 9.71118 14.7499 9.73184 14.7223C9.7525 14.6947 9.78152 14.6746 9.81449 14.6651L11.747 14.1615C11.8559 14.1331 11.9554 14.0757 12.035 13.9954C12.1146 13.915 12.1714 13.8146 12.1996 13.7046L12.6979 11.7526C12.7072 11.7191 12.727 11.6897 12.7544 11.6687C12.7818 11.6477 12.8152 11.6364 12.8496 11.6364C12.884 11.6364 12.9174 11.6477 12.9448 11.6687C12.9722 11.6897 12.992 11.7191 13.0013 11.7526L13.4993 13.7046C13.5274 13.8147 13.5842 13.9152 13.6638 13.9956C13.7434 14.076 13.8429 14.1334 13.9519 14.1618L15.8844 14.6648C15.9176 14.6741 15.9469 14.6941 15.9678 14.7218C15.9887 14.7495 16 14.7834 16 14.8182C16 14.853 15.9887 14.8869 15.9678 14.9146C15.9469 14.9423 15.9176 14.9623 15.8844 14.9715L13.9519 15.4746C13.8429 15.503 13.7434 15.5604 13.6638 15.6408C13.5842 15.7212 13.5274 15.8217 13.4993 15.9318L13.0009 17.8838C12.9917 17.9172 12.9718 17.9467 12.9445 17.9677C12.9171 17.9886 12.8837 18 12.8493 18C12.8149 18 12.7815 17.9886 12.7541 17.9677C12.7267 17.9467 12.7069 17.9172 12.6976 17.8838L12.1996 15.9318Z"
            fill="#5133EA"
            stroke="#5133EA"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.42835 10.4545C8.41228 10.3916 8.37981 10.3342 8.33432 10.2882C8.28883 10.2423 8.23199 10.2095 8.16969 10.1933L7.06542 9.90563C7.04658 9.90023 7.03 9.88877 7.0182 9.87299C7.00639 9.85721 7 9.83796 7 9.81818C7 9.7984 7.00639 9.77916 7.0182 9.76337C7.03 9.74759 7.04658 9.73613 7.06542 9.73073L8.16969 9.44292C8.23196 9.4267 8.2888 9.39393 8.33428 9.34802C8.37977 9.3021 8.41225 9.24472 8.42835 9.18183L8.7131 8.06641C8.71839 8.0473 8.72973 8.03047 8.74538 8.01848C8.76103 8.00649 8.78013 8 8.79977 8C8.81941 8 8.83851 8.00649 8.85416 8.01848C8.86981 8.03047 8.88114 8.0473 8.88644 8.06641L9.17101 9.18183C9.18708 9.24475 9.21955 9.30218 9.26503 9.34812C9.31052 9.39407 9.36737 9.42687 9.42966 9.4431L10.5339 9.73055C10.5529 9.73584 10.5697 9.74728 10.5816 9.76311C10.5935 9.77894 10.6 9.79828 10.6 9.81818C10.6 9.83808 10.5935 9.85743 10.5816 9.87326C10.5697 9.88909 10.5529 9.90053 10.5339 9.90582L9.42966 10.1933C9.36737 10.2095 9.31052 10.2423 9.26503 10.2882C9.21955 10.3342 9.18708 10.3916 9.17101 10.4545L8.88626 11.57C8.88096 11.5891 8.86963 11.6059 8.85398 11.6179C8.83833 11.6299 8.81923 11.6364 8.79959 11.6364C8.77995 11.6364 8.76085 11.6299 8.7452 11.6179C8.72955 11.6059 8.71821 11.5891 8.71292 11.57L8.42835 10.4545Z"
            stroke="#5133EA"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default MemoDocument

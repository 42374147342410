import React from 'react'

const GearIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M9.35012 19.3711L9.93457 20.6856C10.1083 21.0768 10.3918 21.4093 10.7508 21.6426C11.1097 21.8759 11.5287 22.0001 11.9568 22C12.3849 22.0001 12.8038 21.8759 13.1628 21.6426C13.5217 21.4093 13.8053 21.0768 13.979 20.6856L14.5635 19.3711C14.7715 18.9047 15.1215 18.5159 15.5635 18.26C16.0082 18.0034 16.5228 17.8941 17.0335 17.9478L18.4635 18.1C18.8891 18.145 19.3187 18.0656 19.7001 17.8713C20.0816 17.6771 20.3984 17.3763 20.6123 17.0056C20.8265 16.635 20.9286 16.2103 20.9061 15.7829C20.8836 15.3555 20.7376 14.9438 20.4857 14.5978L19.639 13.4344C19.3375 13.0171 19.1764 12.5148 19.179 12C19.1789 11.4866 19.3415 10.9864 19.6435 10.5711L20.4901 9.40778C20.742 9.06175 20.888 8.65007 20.9105 8.22267C20.933 7.79528 20.831 7.37054 20.6168 7C20.4029 6.62923 20.086 6.32849 19.7046 6.13423C19.3232 5.93997 18.8936 5.86053 18.4679 5.90556L17.0379 6.05778C16.5272 6.11141 16.0127 6.00212 15.5679 5.74556C15.125 5.48825 14.775 5.09736 14.5679 4.62889L13.979 3.31444C13.8053 2.92317 13.5217 2.59072 13.1628 2.3574C12.8038 2.12408 12.3849 1.99993 11.9568 2C11.5287 1.99993 11.1097 2.12408 10.7508 2.3574C10.3918 2.59072 10.1083 2.92317 9.93457 3.31444L9.35012 4.62889C9.14305 5.09736 8.79299 5.48825 8.35012 5.74556C7.90534 6.00212 7.39079 6.11141 6.88012 6.05778L5.44568 5.90556C5.02001 5.86053 4.59042 5.93997 4.20899 6.13423C3.82757 6.32849 3.51069 6.62923 3.29679 7C3.08261 7.37054 2.98057 7.79528 3.00305 8.22267C3.02554 8.65007 3.17158 9.06175 3.42345 9.40778L4.27012 10.5711C4.57206 10.9864 4.73466 11.4866 4.73456 12C4.73466 12.5134 4.57206 13.0137 4.27012 13.4289L3.42345 14.5922C3.17158 14.9382 3.02554 15.3499 3.00305 15.7773C2.98057 16.2047 3.08261 16.6295 3.29679 17C3.5109 17.3706 3.82782 17.6712 4.20918 17.8654C4.59055 18.0596 5.02004 18.1392 5.44568 18.0944L6.87568 17.9422C7.38634 17.8886 7.90089 17.9979 8.34568 18.2544C8.7902 18.511 9.14188 18.902 9.35012 19.3711Z"
      stroke="#1B212D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.955 15C13.6119 15 14.955 13.6569 14.955 12C14.955 10.3431 13.6119 9 11.955 9C10.2981 9 8.955 10.3431 8.955 12C8.955 13.6569 10.2981 15 11.955 15Z"
      stroke="#1B212D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default GearIcon

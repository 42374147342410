import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom'

import ChatApp from './ChatApp'
import HomeScreen from './HomeScreen'
import MainChat from './MainChat'
import MatterWorkflow from './workflow/MatterWorkflow'

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/chat" element={<ChatApp />}>
          <Route index element={<HomeScreen />} />
          <Route
            path=":id"
            element={
              <>
                <MainChat />
                <MatterWorkflow />
              </>
            }
          />
        </Route>
        <Route path="*" element={<Navigate to="/chat" />} />
      </Routes>
    </Router>
  )
}

export default App

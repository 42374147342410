import PropTypes from 'prop-types'
import React from 'react'

import CheckMarkIcon from '../../assets/CheckMarkIcon'

const StepConfirmed = ({ heading, content }) => {
  return (
    <div className="rounded-lg flex justify-between items-start">
      <div>
        <p className="text-txt-default-default font-medium text-base mb-2">
          {heading}
        </p>
        <p
          className="text-txt-default-default font-normal text-base mt-1"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
      <div className="ml-4">
        <CheckMarkIcon />
      </div>
    </div>
  )
}

StepConfirmed.propTypes = {
  content: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
}

export default StepConfirmed

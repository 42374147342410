import axios from 'axios'
import { runInAction } from 'mobx'

import addFlash from '../actions/AddFlash'
import { extendSessionTimeout } from '../actions/sessionActions'

// Set withCredentials to true for all requests
axios.defaults.withCredentials = true

export const fetchMatters = async (store) => {
  extendSessionTimeout(store)

  const sortParams =
    store.sortParams ||
    new Map([
      ['sortBy', 'updated_at'],
      ['sortOrder', 'desc'],
    ])
  const filterParams = store.filterParams || new Map()

  try {
    const params = new URLSearchParams()

    // Append sort parameters to the URL
    sortParams.forEach((value, key) => {
      params.append(key, value)
    })

    // Append filter parameters directly to the URL
    filterParams.forEach((value, key) => {
      params.append(key, value)
    })

    const response = await axios.get('/api/client/v1/matters', {
      params: params,
    })

    return response.data
  } catch (error) {
    console.error('Error fetching matters:', error)
    throw error
  }
}

export const updateMatter = async (store, updatedMatter) => {
  extendSessionTimeout(store)

  try {
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content')
    const response = await axios.patch(
      `/api/client/v1/matters/${updatedMatter.id}`,
      {
        matter: updatedMatter,
      },
      {
        headers: {
          'X-CSRF-Token': csrfToken,
        },
      }
    )

    if (response.status === 200) {
      runInAction(() => {
        const index = store.matters.findIndex(
          (matter) => matter.id === response.data.id
        )
        if (index === -1) {
          console.error('Failed to find matter in list')
          return false
        }
        store.matters[index] = response.data
        store.selectedMatter = response.data
        return true
      })
      return true
    } else {
      console.error('Failed to update matter')
      return false
    }
  } catch (error) {
    console.error('Error updating matter:', error)
    throw error
  }
}

export const deleteMatter = async (store, matterId) => {
  extendSessionTimeout(store)

  try {
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content')
    await axios.delete(
      `/api/client/v1/matters/${matterId}`, // The URL to send the DELETE request to
      {
        headers: {
          'X-CSRF-Token': csrfToken, // Custom headers for the request, including CSRF protection
        },
      }
    )
  } catch (error) {
    console.error('Error deleting matter:', error)
    throw error
  }
}

export const undoDeleteMatter = async (store, matterId) => {
  extendSessionTimeout(store)

  try {
    await axios.get(`/api/client/v1/matters/${matterId}/undo_delete`, {})
  } catch (error) {
    console.error('Error deleting matter:', error)
    throw error
  }
}

export const createMatter = async (store) => {
  extendSessionTimeout(store)

  try {
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content')
    const response = await axios.post(
      '/api/client/v1/matters',
      {
        matter: {
          country_code: store.user.enterprise.country_code,
          enterprise_id: store.user.enterprise.id,
          enterprise_name: store.user.enterprise.name,
          enterprise_internal: store.user.enterprise.internal,
          user_full_name: store.user.full_name,
          state_full_name: store.user.enterprise.state,
        },
      },
      {
        headers: {
          'X-CSRF-Token': csrfToken,
        },
      }
    )

    if (response.status === 200 || response.status === 201) {
      if (response.data && response.data.id) {
        return response.data
      } else {
        console.error('Failed to create matter: Invalid response data')
        return false
      }
    } else {
      console.error('Failed to create matter')
      return false
    }
  } catch (error) {
    if (error.response?.data?.error) {
      addFlash(store, 'error', error.response.data.error)
    }
    throw error
  }
}

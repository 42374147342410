import { useContext } from 'react'

import Store from './Store'
import StoreContext from './StoreContext'

export function useStore(): Store {
  const store = useContext(StoreContext)

  if (store === null) {
    throw new Error('useStore must be used within a StoreProvider')
  }

  return store as Store
}

import React from 'react'

const CreateIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_547_61543)">
      <path
        d="M9.52807 1.94385H2.89513C2.39251 1.94385 1.91047 2.14351 1.55507 2.49892C1.19966 2.85432 1 3.33636 1 3.83897V17.1049C1 17.6075 1.19966 18.0895 1.55507 18.4449C1.91047 18.8003 2.39251 19 2.89513 19H16.161C16.6636 19 17.1457 18.8003 17.5011 18.4449C17.8565 18.0895 18.0561 17.6075 18.0561 17.1049V10.4719"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5691 1.58874C15.9461 1.21178 16.4574 1 16.9905 1C17.5236 1 18.0349 1.21178 18.4118 1.58874C18.7888 1.9657 19.0006 2.47698 19.0006 3.01009C19.0006 3.54319 18.7888 4.05447 18.4118 4.43143L9.87144 12.9728C9.64644 13.1976 9.36847 13.3621 9.06316 13.4513L6.34082 14.2472C6.25928 14.271 6.17285 14.2724 6.09057 14.2514C6.0083 14.2303 5.9332 14.1875 5.87314 14.1274C5.81309 14.0674 5.77028 13.9923 5.7492 13.91C5.72812 13.8277 5.72954 13.7413 5.75333 13.6597L6.54928 10.9374C6.63885 10.6323 6.80373 10.3547 7.02875 10.1301L15.5691 1.58874Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_547_61543">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default CreateIcon

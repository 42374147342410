import PropTypes from 'prop-types'
import React, { useState } from 'react'

import ActivityItemContainer from './ActivityItemContainer'
import AttachmentItem from './AttachmentItem'
import { scrollAndHighlightMessage } from '../../actions/activityItemActions'
import { useStore } from '../../useStore'

const AttachmentItemGroup = ({ activities }) => {
  const store = useStore()
  const [isExpanded, setIsExpanded] = useState(false)
  const maxVisibleItems = 1

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  const visibleActivities = isExpanded
    ? activities
    : activities.slice(0, maxVisibleItems)

  return (
    <ActivityItemContainer
      activity={activities[0]}
      description={`You uploaded ${activities.length} documents`}
    >
      {/* Render visible activities */}
      {visibleActivities.map((activity) => (
        <AttachmentItem
          key={activity.id}
          activity={activity}
          onClick={() => scrollAndHighlightMessage(store, activity)}
        />
      ))}

      {/* Show 'Show More' or 'Show Less' toggle */}
      {activities.length > maxVisibleItems && (
        <div
          onClick={handleToggleExpand}
          className="text-txt-brand-default cursor-pointer text-xs"
        >
          {isExpanded ? 'Show less' : 'Show all'}
        </div>
      )}
    </ActivityItemContainer>
  )
}

AttachmentItemGroup.propTypes = {
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      chat_thread_message_id: PropTypes.string.isRequired,
      activity_item: PropTypes.shape({
        description: PropTypes.string,
      }),
    })
  ).isRequired,
}

export default AttachmentItemGroup

import PropTypes from 'prop-types'
import React from 'react'

import timezonedDatetime from '../../DateTimeUtils'
import { useStore } from '../../useStore'
import WarningIcon from '../assets/WarningIcon'

const ActivityItemHeader = ({ activity, description }) => {
  const isFailedActivity =
    activity.activity_item.status === 'FAILED' ||
    activity.activity_item.status === 'INVALID' ||
    activity.activity_item.status === 'REJECTED'

  const store = useStore()

  return (
    <div className="flex items-center">
      {/* Time */}
      <p className="text-xs text-txt-default-secondary font-normal leading-tight overflow-hidden text-nowrap">
        {timezonedDatetime(activity.created_at_formatted, store, 'timeFormat')}
      </p>
      <span className="mx-2 align-middle text-txt-default-secondaryHover">
        •
      </span>
      {/* Activity Type */}
      <p
        className={`text-xs font-normal leading-tight truncate overflow-hidden ${
          isFailedActivity
            ? 'text-txt-danger-default'
            : 'text-txt-default-secondary'
        }`}
      >
        {description}
      </p>
      <p className="ml-1">
        {isFailedActivity &&
          activity.activity_item_type === 'AttachmentActivityItem' && (
            <WarningIcon />
          )}
      </p>
    </div>
  )
}

ActivityItemHeader.propTypes = {
  activity: PropTypes.shape({
    created_at_formatted: PropTypes.string.isRequired,
    activity_item_type: PropTypes.string.isRequired,
    activity_item: PropTypes.shape({
      status: PropTypes.string,
    }).isRequired,
  }).isRequired,
  description: PropTypes.string.isRequired,
}

export default ActivityItemHeader

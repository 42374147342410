import PropTypes from 'prop-types'
import React from 'react'

import CanadianDropdown from './CanadianDropdown'
import CancelButton from './CancelButton'
import SubmitButton from './SubmitButton'
import USDropdown from './USDropdown'
import { useStore } from '../../../useStore'

const Step3 = ({
  jurisdiction,
  setJurisdiction,
  state,
  setState,
  handleSubmit,
  handleCancel,
  additionalContext,
}) => {
  const store = useStore()
  const countryCode = store.user.enterprise.country_code
  const userIsCanadian = countryCode === 'CA'

  return (
    <div
      className={`w-[22rem] my-4 p-6 bg-white rounded-lg shadow-md ${userIsCanadian ? 'w-1/2' : 'w-full'}`}
      style={{ borderRadius: '0 8px 8px 8px' }}
    >
      <div>
        {userIsCanadian ? (
          <CanadianDropdown
            state={state}
            setJurisdiction={setJurisdiction}
            additionalContext={additionalContext}
            setState={setState}
          />
        ) : (
          <USDropdown
            state={state}
            setState={setState}
            jurisdiction={jurisdiction}
            setJurisdiction={setJurisdiction}
            additionalContext={additionalContext}
          />
        )}
      </div>

      <div className="flex justify-end mt-6 space-x-4">
        <CancelButton handleCancel={() => handleCancel(3)} />
        <SubmitButton
          handleSubmit={() => {
            handleSubmit(3, { jurisdiction, state })
          }}
          disabled={false}
        />
      </div>
    </div>
  )
}

Step3.propTypes = {
  jurisdiction: PropTypes.string,
  setJurisdiction: PropTypes.func,
  state: PropTypes.string,
  setState: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  additionalContext: PropTypes.object,
}

export default Step3

import PropTypes from 'prop-types'
import React from 'react'

import CheckMarkIcon from '../../assets/CheckMarkIcon'

const Step3Confirmed = ({ content, countryCode }) => {
  const canadaStep = () => {
    return (
      <div className="rounded-lg flex justify-between items-start">
        <div>
          <p className="text-txt-default-default font-medium text-base mb-2">
            Jurisdiction
          </p>
          <p
            className="text-txt-default-default font-normal text-base mt-1"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
        <div className="ml-4">
          <CheckMarkIcon />
        </div>
      </div>
    )
  }

  const usStep = () => {
    const step3Info = content.split(',')
    const StateOrForumLabel = step3Info[0] === 'State' ? 'State' : 'Forum'
    return (
      <div className="rounded-lg flex justify-between items-start">
        <div>
          <p className="text-txt-default-default font-medium text-base mb-1">
            Jurisdiction
          </p>
          <p className="text-txt-default-default font-normal text-base">
            {step3Info[0]}
          </p>
        </div>

        <div className="ml-4">
          <p className="text-txt-default-default font-medium text-base mb-1">
            {StateOrForumLabel}
          </p>
          <p className="text-txt-default-default font-normal text-base">
            {step3Info[1]}
          </p>
        </div>

        <div className="ml-4">
          <CheckMarkIcon />
        </div>
      </div>
    )
  }

  return countryCode === 'CA' ? canadaStep() : usStep()
}

Step3Confirmed.propTypes = {
  content: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
}

export default Step3Confirmed

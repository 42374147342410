import React from 'react'

const TrashIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 4.6H18.2M16.4 4.6V17.2C16.4 18.1 15.5 19 14.6 19H5.6C4.7 19 3.8 18.1 3.8 17.2V4.6M6.5 4.6V2.8C6.5 1.9 7.4 1 8.3 1H11.9C12.8 1 13.7 1.9 13.7 2.8V4.6M8.3 9.1V14.5M11.9 9.1V14.5"
      stroke="#1B212D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default TrashIcon

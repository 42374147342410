import React from 'react'

import SearchIcon from './assets/SearchIcon'

const NoResultsFound = () => {
  return (
    <div className="flex flex-col items-center justify-center p-7 pt-[50px]">
      <p className="text-sm font-medium mt-10 mb-1 text-txt-default-secondary">
        No matching results
      </p>
      <p className="text-sm mb-6 font-medium text-txt-default-secondary">
        found.
      </p>
      <div className="w-[64px] h-[10px]  bg-[#F2F2F2] relative rounded-xl left-[-4rem]  mt-[12px]"></div>

      <div className="relative mt-4">
        {/* Bottom Shape (rotated) */}
        <div className="w-[208px] h-[48px] bg-[#F2F2F2] absolute top-[50px] rounded-xl left-1/2 transform -translate-x-1/2 rotate-[-7.4deg] shadow-md">
          <div className="w-[184px] h-[24px] bg-[#FFFFFF] relative mx-auto mt-[12px] rounded-lg">
            <div className="w-[160px] h-[8px]  bg-[#F2F2F2] absolute ml-4 mb-12 mt-[8px] rounded-lg"></div>
          </div>
        </div>

        {/* Top Shape */}
        <div className="w-[208px] h-[48px] bg-[#FAFAFA] absolute top-0 rounded-xl left-1/2 transform -translate-x-1/2 shadow-md">
          <div className="w-[184px] h-[24px] bg-[#FFFFFF] relative mx-auto mt-[12px] rounded-lg">
            <div className="w-[160px] h-[8px]  bg-[#F2F2F2] absolute ml-4 mb-12 mt-[8px] rounded-lg"></div>
          </div>
        </div>

        {/* Magnifying Glass */}
        <div className="absolute top-[26px] left-1/2 transform -translate-x-1/2 w-14 h-14 rounded-xl flex items-center justify-center z-10">
          <SearchIcon className="w-8 h-8 text-white" />
        </div>
      </div>
    </div>
  )
}

export default NoResultsFound

import PropTypes from 'prop-types'
import React from 'react'

const DownloadIcon = ({ stroke = '#454548', classNames }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    className={classNames || ''}
  >
    <path
      d="M7.6 11.8889V1M7.6 11.8889L2.8 7.22222M7.6 11.8889L12.4 7.22222M13.2 15H2"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

DownloadIcon.propTypes = {
  classNames: PropTypes.string,
  stroke: PropTypes.string,
}

export default DownloadIcon

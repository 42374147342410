import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { fetchStates } from '../../../apis/fetchStatesApi'
import { useStore } from '../../../useStore'

const USDropdown = ({
  state,
  setState,
  jurisdiction,
  setJurisdiction,
  additionalContext,
}) => {
  const dropdownLabel = jurisdiction === 'State' ? 'State' : 'Forum'
  const [states, setStates] = useState([])
  const store = useStore()
  const normalizeValue = (value) => {
    return value === 'none' || value === undefined || value === 'null'
      ? ''
      : value
  }

  const setDefaultValues = async (states) => {
    const normalizedJurisdiction = normalizeValue(
      additionalContext?.jurisdiction
    )
    const normalizedState = normalizeValue(additionalContext?.state)
    const normalizedUserState = normalizeValue(store.user.enterprise.state)

    if (normalizedJurisdiction !== '') {
      setJurisdiction(normalizedJurisdiction)
    } else {
      setJurisdiction('Federal')
    }

    if (normalizedState !== '') {
      setState(normalizedState)
    } else if (normalizedUserState !== '') {
      setState(normalizedUserState)
    } else if (states.length > 0) {
      setState(states[0].name)
    }
  }

  const loadStates = async () => {
    try {
      const data = await fetchStates(store, 'US')
      setStates(data)
      setDefaultValues(data)
    } catch (error) {
      console.error('Error fetching states:', error)
    }
  }

  if (states.length === 0) {
    loadStates()
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 items-start w-full">
      {/* Jurisdiction Dropdown */}
      <div className="w-full">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Jurisdiction
        </label>
        <div className="w-full border rounded-md border-brd-default-default py-1">
          <select
            className="w-full py-2 border-r-8 border-transparent px-3 text-sm focus:outline-none"
            value={jurisdiction}
            onChange={(e) => {
              setJurisdiction(e.target.value)
            }}
          >
            <option value="Federal">Federal</option>
            <option value="State">State</option>
          </select>
        </div>
      </div>

      {/* State/Forum Dropdown */}
      <div className="w-full">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          {dropdownLabel}
        </label>
        <div className="w-full border rounded-md border-brd-default-default py-1">
          <select
            className="w-full py-2 border-r-8 border-transparent px-3 text-sm focus:outline-none"
            value={state}
            onChange={(e) => {
              setState(e.target.value)
            }}
          >
            {states.map((state) => (
              <option key={state.id} value={state.name}>
                {state.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  )
}

USDropdown.propTypes = {
  state: PropTypes.string.isRequired,
  setState: PropTypes.func.isRequired,
  jurisdiction: PropTypes.string.isRequired,
  setJurisdiction: PropTypes.func.isRequired,
  additionalContext: PropTypes.shape({
    jurisdiction: PropTypes.string,
    state: PropTypes.string,
  }).isRequired,
}

export default USDropdown

import { observer } from 'mobx-react-lite'
import React from 'react'

interface FailedLoadingProps {
  updatedAt: string
}

export const FailedLoading = observer(({ updatedAt }: FailedLoadingProps) => {
  return (
    <div className="loading-bar self-stretch">
      {new Date().getTime() - new Date(updatedAt).getTime() <= 5000 && (
        <div
          className="w-full bg-brd-danger-secondary flex items-center my-1"
          style={{
            borderRadius: '10px',
            height: '6px',
          }}
        >
          <div
            className="h-full bg-bgCol-danger-default"
            style={{
              width: '40%',
              borderRadius: '10px',
              transition: 'width 1s linear', // Smooth width transition
            }}
          />
        </div>
      )}
      <div className="flex flex-row justify-between mb-1 text-txt-danger-default">
        <span className="text-xs leading-[1.125rem]">Analysis failed.</span>
      </div>
    </div>
  )
})

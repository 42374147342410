// This component represents the selected chat header when the user selects a chat.

import PropTypes from 'prop-types'
import React, { useState } from 'react'

import MatterHeader from './MatterHeader'
import AlexiLogo from '../assets/alexiLogo'
import SettingsModal from '../SettingsModal'

const SelectedChatHeader = ({ selectedMatter, user }) => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)

  return (
    <div className="flex items-center justify-between px-6 py-5 border-b">
      <div className="flex items-center">
        <MatterHeader
          matter={selectedMatter}
          setIsSettingsOpen={setIsSettingsOpen}
        />
      </div>
      <div className="flex -space-x-2 overflow-hidden ml-4 py-1">
        <div className="flex justify-center items-center bg-bgCol-brand-default rounded-full w-10 h-10 border border-white shadow">
          <AlexiLogo color={'#ffffff'} width={16} height={16} />
        </div>
        <div className="flex justify-center items-center uppercase bg-gray-50 rounded-full w-10 h-10 border border-white shadow">
          <span className="text-lg font-semibold text-black">
            {user?.initials}
          </span>
        </div>
      </div>
      {isSettingsOpen && (
        <SettingsModal
          matter={selectedMatter}
          onClose={() => setIsSettingsOpen(false)}
        />
      )}
    </div>
  )
}

SelectedChatHeader.propTypes = {
  selectedMatter: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
}

export default SelectedChatHeader

import { action, runInAction } from 'mobx'

import Store from '../Store'

let intervalId: ReturnType<typeof setInterval> | null = null

export const getActivitiesLoading = (store: Store, activityId: string) => {
  return store.activitiesLoading[activityId]
}

interface setActivitiesLoadingProps {
  store: Store
  activityId: string
  durationEstimate: number
  durationStartTime: number
  durationElapsed: number
}

export const setActivitiesLoading = action(
  ({
    store,
    activityId,
    durationEstimate,
    durationStartTime,
    durationElapsed,
  }: setActivitiesLoadingProps) => {
    store.activitiesLoading[activityId] = {
      durationEstimate,
      durationStartTime,
      durationElapsed,
    }

    saveActivitiesLoadingToLocalStorage(store)
  }
)

export const startActivityLoading = action(
  (store: Store, activityId: string) => {
    // Clear any existing interval before starting a new one
    if (intervalId !== null) {
      clearInterval(intervalId as unknown as number) // Cast as unknown, then as number
    }

    const activity = getActivitiesLoading(store, activityId)

    const startTime = activity.durationStartTime
    const durationEstimate = activity.durationEstimate

    intervalId = setInterval(() => {
      const now = Date.now()
      const elapsedSeconds = Math.floor((now - startTime) / 1000)

      runInAction(() => {
        if (elapsedSeconds >= durationEstimate) {
          clearInterval(intervalId as unknown as number) // Cast as unknown, then as number
          store.activitiesLoading[activityId].durationElapsed = durationEstimate
        } else {
          store.activitiesLoading[activityId].durationElapsed = elapsedSeconds
        }

        saveActivitiesLoadingToLocalStorage(store)
      })
    }, 1000)
  }
)

const saveActivitiesLoadingToLocalStorage = (store: Store) => {
  localStorage.setItem(
    'activitiesLoading',
    JSON.stringify(store.activitiesLoading)
  )
}

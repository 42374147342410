import { action } from 'mobx'

/* eslint-disable-next-line max-params */
const addFlash = action((store, type, message, metadata) => {
  const id = store.flashes.length ? store.flashes.length : 1
  const flash = { id: id, type: type, message: message, metadata: metadata }

  store.flashes.push(flash)

  const animationDuration =
    metadata &&
    Object.entries(metadata).length > 0 &&
    metadata.subType === 'deleteMatter'
      ? 15
      : 5

  setTimeout(
    action(() => {
      store.flashes = store.flashes.filter((f) => f.id !== id)
    }),
    animationDuration * 1000
  )
})

export default addFlash

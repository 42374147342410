import { observer } from 'mobx-react-lite'
import React from 'react'

import { removeFile } from '../../actions/fileActions'
import { useStore } from '../../useStore'
import CircularLoadingIcon from '../assets/CircularLoadingIcon'
import CloseIcon from '../assets/CloseIcon'
import DocumentIcon from '../assets/DocumentIcon'
import Tooltip from '../shared/Tooltip'

enum Status {
  Uploading = 'uploading',
  Uploaded = 'uploaded',
  Validated = 'validated',
  Error = 'error',
}

interface File {
  id: string
  byte_size: number
}

interface Attachment {
  content_type: string
  filename: string
  status?: Status
  progress?: number
  error_msg?: string
  file: File
}

interface InputFileCardProps {
  attachment: Attachment
}

// Function to format file size
const formatFileSize = (sizeInBytes: number): string => {
  const fileSizeInKB = sizeInBytes / 1024
  if (fileSizeInKB >= 1024) {
    return `${(fileSizeInKB / 1024).toFixed(1)} MB`
  } else {
    return `${fileSizeInKB.toFixed(1)} KB`
  }
}

const InputFileCard: React.FC<InputFileCardProps> = ({ attachment }) => {
  const store = useStore()

  const handleRemoveFileClick = (localAttachment: Attachment) => {
    removeFile(store, localAttachment.file.id)
  }

  function renderIcon() {
    if (attachment.status === Status.Validated && attachment.file.byte_size) {
      return <DocumentIcon />
    } else {
      return (
        <div className="flex justify-center items-center w-6 h-6 bg-bgCol-default-default rounded-3xl">
          <CircularLoadingIcon />
        </div>
      )
    }
  }

  return (
    <div
      key={attachment.file.id}
      className="relative p-3 border border-brd-neutral-default bg-bgCol-default-default rounded-lg group min-w-64"
    >
      <div className="flex items-center">
        <div className="flex-shrink-0 p-2 bg-bgCol-neutral-default rounded w-10 h-10">
          {renderIcon()}
        </div>
        <div
          className={`ml-2 flex-1 ${!attachment.file.byte_size ? 'flex items-center justify-center' : ''}`}
        >
          <Tooltip tooltipContents={attachment.filename} position="above">
            <p className="text-sm font-medium truncate w-40">
              {attachment.filename}
            </p>
          </Tooltip>
          {attachment.status === Status.Validated &&
            attachment.file.byte_size && (
              <p className="text-xs text-txt-default-secondary">
                {formatFileSize(attachment.file.byte_size)}
              </p>
            )}
        </div>
      </div>

      {attachment.status === Status.Validated && (
        <div className="absolute top-0 right-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
          <button
            onClick={() => handleRemoveFileClick(attachment)}
            type="button"
            className="absolute bottom-[-0.5rem] right-[-0.5rem] text-black-500 hover:black focus:outline-none"
          >
            <div className="bg-white p-[2px] shadow-sm rounded-md hover:bg-bgCol-default-defaultHover">
              <CloseIcon />
            </div>
          </button>
        </div>
      )}

      {attachment.status === Status.Error && (
        <div className="flex">
          <div className="w-1/2 pt-1">
            <div className="absolute top-0 left-0 h-full bg-purple-500" />
            {attachment.error_msg}
          </div>
        </div>
      )}
    </div>
  )
}

export default observer(InputFileCard)

import { action, runInAction } from 'mobx'
import React from 'react'

import { fetchFiles } from '../apis/filesApi'
import { rollbarConfig } from '../rollbarConfig'

const removeFile = action((store, blobId) => {
  store.uploadedFiles = store.uploadedFiles.filter(
    (attachment) => attachment.file.id !== blobId
  )
})

const setFetchedDocuments = action(async (store) => {
  try {
    const documents = await fetchFiles(store)

    if (documents.length === 0) {
      return
    }

    runInAction(() => {
      store.documents = documents.map((document) => ({
        ...document,
        ref: React.createRef(),
      }))
    })
  } catch (error) {
    rollbarConfig(store)?.error(error)
  }
})

export { removeFile, setFetchedDocuments }

import { action } from 'mobx'
import React from 'react'

export const initializeMessages = action((store, matterId) => {
  if (!store.messages[matterId]) {
    store.messages[matterId] = []
  }
})

export const getMessages = (store, matterId) => {
  return store.messages[matterId]
}

export const findMessage = (store, matterId, messageId) => {
  return store.messages[matterId].find((m) => m.id === messageId)
}

export const addMessage = action((store, matterId, message) => {
  if (!message.ref) {
    message.ref = React.createRef()
  }

  addUniqueMessage(store.messages[matterId], message)
})

export const setMessages = action((store, matterId, messages) => {
  store.messages[matterId] = messages.map((message) => ({
    ...message,
    ref: React.createRef(), // Ensure each message has a ref
  }))
})

export const addToExistingMessage = action((existingMessage, content) => {
  existingMessage.content += content
})

export const createNewMessage = action(
  (
    store,
    nextMessage,
    matterId,
    userId,
    currentType,
    content,
    messageAdditionalContent
  ) => {
    store.messages[matterId].push({
      id: nextMessage.id,
      user_id: userId,
      matter_id: matterId,
      role: 'system',
      content: content,
      current_type: currentType,
      response_additional_context: messageAdditionalContent,
    })
  }
)

const addUniqueMessage = (messageList, message) => {
  const existingMessageIndex = messageList.findIndex((m) => m.id === message.id)

  if (existingMessageIndex === -1) {
    messageList.push(message)
  }
}

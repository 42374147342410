import PropTypes from 'prop-types'
import React from 'react'

import DownloadIcon from '../assets/DownloadIcon'

const LatestMessageButton = ({ store, scrollToBottom }) => {
  return (
    <div
      onClick={() => {
        store.showLatestMessagesButton = false
        scrollToBottom()
      }}
      className="absolute bottom-44 left-1/2 transform -translate-x-1/2 px-4 py-2 bg-bgCol-brand-default text-txt-utilities-default rounded-full flex items-center cursor-pointer shadow-lg z-50"
    >
      <DownloadIcon stroke="#FAFAFA" className="w-4 h-4 transform rotate-180" />
      <span className="text-xs font-medium leading-4 ml-1">
        Latest messages
      </span>
    </div>
  )
}

LatestMessageButton.propTypes = {
  store: PropTypes.shape({
    showLatestMessagesButton: PropTypes.bool,
  }),
  scrollToBottom: PropTypes.func.isRequired,
}

export default LatestMessageButton

import PropTypes from 'prop-types'
import React from 'react'

import ActivityItemContainer from './ActivityItemContainer'
import AttachmentItem from './AttachmentItem'
import { scrollAndHighlightMessage } from '../../actions/activityItemActions'
import { useStore } from '../../useStore'

const AttachmentItemSingle = ({ activity }) => {
  const store = useStore()

  return (
    <ActivityItemContainer
      activity={activity}
      description={'You uploaded a document'}
    >
      <AttachmentItem
        key={activity.id}
        activity={activity}
        onClick={() => scrollAndHighlightMessage(store, activity)}
      />
    </ActivityItemContainer>
  )
}

AttachmentItemSingle.propTypes = {
  activity: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    activity_item: PropTypes.shape({
      description: PropTypes.string,
      duration_estimate: PropTypes.number,
      status: PropTypes.string,
    }),
    ref: PropTypes.object,
    chat_thread_message_id: PropTypes.string,
  }).isRequired,
}

export default AttachmentItemSingle

import React, { useState, useRef, useEffect } from 'react'

import InfoIcon from '../../assets/InfoIcon'

const MemoModalAiDisclaimer = () => {
  const [isExpanded, setIsExpanded] = useState(false)
  const scrollWrapper = useRef(null)

  useEffect(() => {
    if (isExpanded === true) {
      scrollWrapper.current.scrollIntoView()
    }
  }, [isExpanded])

  return (
    <div
      className="grid gap-2 bg-bgCol-neutral-default rounded-lg p-4"
      ref={scrollWrapper}
    >
      <div className="row-start-1 col-start-1 self-center">
        <InfoIcon />
      </div>
      <div className="row-start-1 col-start-2 self-center">
        <h2 className="font-medium">
          Understanding your AI-generated legal memo.
        </h2>
      </div>
      <div className="row-start-2 col-start-2 text-txt-neutral-default text-sm">
        <p className="pb-2">
          This memo was created using advanced AI, including a mix of
          Alexi&apos;s proprietary models and GPT models. To ensure accuracy, we
          recommend:
        </p>
        {isExpanded ? (
          <>
            <ul className="list-disc pl-6">
              <li className="pb-2">
                Reviewing any cited cases for their full context; and
              </li>
              <li className="pb-2">
                Confirming the currency of any cited cases.
              </li>
            </ul>
            <p className="pb-2">
              You can trust that all cases and excerpts included in an Alexi
              research memo are genuine. For any questions, reach out to{' '}
              <a
                className="text-txt-brand-default"
                href="mailto:support@alexi.com"
              >
                support@alexi.com
              </a>
              .
            </p>
            <a
              className="font-medium text-txt-brand-default"
              href="#"
              onClick={() => setIsExpanded(false)}
            >
              Read less
            </a>
          </>
        ) : (
          <>
            <a
              className="font-medium text-txt-brand-default"
              href="#"
              onClick={() => setIsExpanded(true)}
            >
              Read more
            </a>
          </>
        )}
      </div>
    </div>
  )
}

export default MemoModalAiDisclaimer

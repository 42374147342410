import PropTypes from 'prop-types'
import React from 'react'

const EmptyActivity = ({ Icon }) => {
  return (
    <div className="bg-bgCol-neutral-default rounded-lg p-4 m-4">
      <div className="bg-white rounded-lg flex shadow-lg px-3 py-2">
        <div className="shrink">
          <Icon />
        </div>
        <div className="grow flex flex-col">
          <div className="p-2">
            <div className="flex">
              <div className="w-1/2 bg-bgCol-neutral-secondary rounded-xl p-1 "></div>
              <div className="w-1 mx-1 bg-bgCol-neutral-secondary rounded-xl p-1 "></div>
              <div className="w-1/2 bg-bgCol-neutral-secondary rounded-xl p-1 "></div>
            </div>
          </div>
          <div className="p-2">
            <div className="bg-bgCol-neutral-secondary rounded-xl p-1"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

EmptyActivity.propTypes = {
  Icon: PropTypes.func.isRequired,
}

export default EmptyActivity

import Rollbar from 'rollbar'

export const rollbarConfig = (store) => {
  if (!store.credentials.rollbarAccessToken) {
    return null
  }

  const config = {
    accessToken: store.credentials.rollbarAccessToken,
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: store.credentials.rollbarEnvironment,
  }

  return new Rollbar(config)
}

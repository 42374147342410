import React from 'react'

const InfoIconWithTriangle = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M10.009 7.40819V11.0053M10.009 14.6025H10.018M18.759 15.5017L11.5648 2.91178C11.4079 2.63498 11.1804 2.40475 10.9055 2.24457C10.6306 2.08439 10.3182 2 10 2C9.68187 2 9.3694 2.08439 9.09451 2.24457C8.81962 2.40475 8.59214 2.63498 8.43527 2.91178L1.241 15.5017C1.08244 15.7763 0.999302 16.088 1 16.4051C1.00071 16.7222 1.08523 17.0334 1.245 17.3073C1.40478 17.5812 1.63412 17.808 1.9098 17.9647C2.18547 18.1214 2.49767 18.2024 2.81475 18.1996H17.2033C17.5188 18.1993 17.8288 18.1159 18.1019 17.9579C18.3751 17.7999 18.6019 17.5729 18.7595 17.2995C18.9171 17.0261 19.0001 16.7161 19 16.4006C18.9999 16.085 18.9168 15.775 18.759 15.5017Z"
      stroke="#FC9D05"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default InfoIconWithTriangle

import React from 'react'

interface DocumentPPTProps {
  fill?: string
}

const DocumentPPT: React.FC<DocumentPPTProps> = ({ fill = '#FAFAFA' }) => (
  <svg
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M86.6671 110H31.1116C28.1647 110 24.0837 110.085 22 108.001C19.9163 105.917 20.0005 101.836 20.0005 98.8894V21.1116C20.0005 18.1647 19.9163 14.0837 22 12C24.0837 9.91627 28.1647 10.0005 31.1116 10.0005H70.0005L97.7782 37.7782V98.8894C97.7782 101.836 97.8624 105.917 95.7787 108.001C93.695 110.085 89.614 110 86.6671 110Z"
      fill={fill}
      stroke="#F2F2F2"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M70 10V32.2222C70 33.6956 70.5853 35.1087 71.6272 36.1506C72.6691 37.1925 74.0821 37.7778 75.5556 37.7778H97.7778"
      stroke="#F2F2F2"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g opacity="0.6">
      <path
        d="M53.0516 67.48C53.0516 68.2933 52.8649 69.04 52.4916 69.72C52.1182 70.3866 51.5449 70.9266 50.7716 71.34C49.9982 71.7533 49.0382 71.96 47.8916 71.96H45.7716V77H42.3516V62.96H47.8916C49.0116 62.96 49.9582 63.1533 50.7316 63.54C51.5049 63.9266 52.0849 64.46 52.4716 65.14C52.8582 65.82 53.0516 66.6 53.0516 67.48ZM47.6316 69.24C48.2849 69.24 48.7716 69.0866 49.0916 68.78C49.4116 68.4733 49.5716 68.04 49.5716 67.48C49.5716 66.92 49.4116 66.4866 49.0916 66.18C48.7716 65.8733 48.2849 65.72 47.6316 65.72H45.7716V69.24H47.6316Z"
        fill="#CECECE"
      />
      <path
        d="M65.532 67.48C65.532 68.2933 65.3454 69.04 64.972 69.72C64.5987 70.3866 64.0254 70.9266 63.252 71.34C62.4787 71.7533 61.5187 71.96 60.372 71.96H58.252V77H54.832V62.96H60.372C61.492 62.96 62.4387 63.1533 63.212 63.54C63.9854 63.9266 64.5654 64.46 64.952 65.14C65.3387 65.82 65.532 66.6 65.532 67.48ZM60.112 69.24C60.7654 69.24 61.252 69.0866 61.572 68.78C61.892 68.4733 62.052 68.04 62.052 67.48C62.052 66.92 61.892 66.4866 61.572 66.18C61.252 65.8733 60.7654 65.72 60.112 65.72H58.252V69.24H60.112Z"
        fill="#CECECE"
      />
      <path
        d="M77.4125 62.96V65.7H73.6925V77H70.2725V65.7H66.5525V62.96H77.4125Z"
        fill="#CECECE"
      />
    </g>
  </svg>
)

export default DocumentPPT

import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import React from 'react'

import TrashIconDeleteMatter from './assets/TrashIconDeleteMatter'
import UndoIcon from './assets/UndoIcon'
import XIcon from './assets/XIcon'
import { useStore } from '../useStore'

const classes = {
  error: 'bg-red-50 border border-red-500 text-black-700',
  info: 'bg-blue-50 border border-blue-500 text-black-700',
  notice: 'bg-yellow-50 border border-yellow-500 text-black-700',
  success: 'bg-green-50 border border-green-700 text-black-700',
}

const progressClasses = {
  error: 'bg-red-500',
  info: 'bg-blue-500',
  notice: 'bg-yellow-500',
  success: 'bg-green-500',
}

const FlashMessage = observer(({ flash }) => {
  const store = useStore()

  const animationClass =
    Object.entries(flash.metadata ?? {}).length > 0 &&
    (flash.metadata ?? {}).subType === 'deleteMatter'
      ? 'animate-progress-15'
      : 'animate-progress'

  return (
    <div className="fixed top-4 left-1/2 transform -translate-x-1/2 z-50">
      <div
        key={flash.id}
        className={`${classes[flash.type] || classes.success} p-4 mb-4 text-sm rounded-lg shadow-md transition-opacity duration-1000 ease-out relative flex items-center`}
        role="alert"
      >
        {flash.metadata &&
        Object.entries(flash.metadata).length > 0 &&
        flash.metadata.subType === 'deleteMatter' ? ( // Conditional rendering
          <>
            <TrashIconDeleteMatter />
            <span className="w-max px-4">{flash.message}</span>

            <div
              className="ml-2 text-primary cursor-pointer inline-flex items-center"
              onClick={() => {
                flash.metadata.onDeleteMatterClick(flash.metadata.matter)
              }}
            >
              <UndoIcon />
              <span className="px-2 text-txt-brand-default">Undo</span>
            </div>
          </>
        ) : (
          <span className="mr-auto">{flash.message}</span>
        )}

        <button
          className="pl-4 ml-4 border-l border-brd-default-default"
          onClick={() => {
            runInAction(() => {
              store.flashes = store.flashes.filter((f) => f.id !== flash.id)
            })
          }}
        >
          <XIcon />
        </button>
        <div
          className={`absolute bottom-0 left-0 h-1 ${progressClasses[flash.type]} ${animationClass}`}
        ></div>
      </div>
    </div>
  )
})

export default FlashMessage

import PropTypes from 'prop-types'
import React from 'react'

import ActivityItemHeader from './ActivityItemHeader'
import { scrollAndHighlightMessage } from '../../actions/activityItemActions'
import { useStore } from '../../useStore'
import MagicIcon from '../assets/MagicIcon'
import { TruncatedText } from '../TruncatedText'

const SummaryDocItem = ({ activity }) => {
  const store = useStore()
  return (
    <div
      key={activity.id}
      onClick={() => scrollAndHighlightMessage(store, activity)}
      ref={activity.ref}
      className="mb-4 mt-4 p-2 hover:bg-bgCol-neutral-default rounded-lg"
    >
      <div className="flex items-start">
        <span className="w-5">
          <MagicIcon />
        </span>

        <div className="ml-2 shrink">
          <ActivityItemHeader
            activity={activity}
            description={'Alexi generated a summary document'}
          />
          <div className="flex flex-col justify-center items-start gap-1 self-stretch pt-2 w-full">
            <TruncatedText text={activity.activity_item.description} />
          </div>
        </div>
      </div>
    </div>
  )
}

SummaryDocItem.propTypes = {
  activity: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    activity_item: PropTypes.shape({
      description: PropTypes.string,
    }),
    ref: PropTypes.object,
  }).isRequired,
}

export default SummaryDocItem

import React from 'react'

const ViewIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
  >
    <path
      d="M1.04375 6.14291C0.985416 5.98576 0.985416 5.81289 1.04375 5.65573C1.61191 4.2781 2.57633 3.10019 3.81475 2.27133C5.05317 1.44248 6.50981 1 8 1C9.49019 1 10.9468 1.44248 12.1852 2.27133C13.4237 3.10019 14.3881 4.2781 14.9562 5.65573C15.0146 5.81289 15.0146 5.98576 14.9562 6.14291C14.3881 7.52054 13.4237 8.69845 12.1852 9.52731C10.9468 10.3562 9.49019 10.7986 8 10.7986C6.50981 10.7986 5.05317 10.3562 3.81475 9.52731C2.57633 8.69845 1.61191 7.52054 1.04375 6.14291Z"
      stroke="#454548"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99833 7.99862C9.15807 7.99862 10.0982 7.05846 10.0982 5.89872C10.0982 4.73898 9.15807 3.79883 7.99833 3.79883C6.83859 3.79883 5.89844 4.73898 5.89844 5.89872C5.89844 7.05846 6.83859 7.99862 7.99833 7.99862Z"
      stroke="#454548"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ViewIcon

import { observer } from 'mobx-react-lite'
import React from 'react'

import FaildUploadIcon from '../assets/FaildUploadIcon'
import Tooltip from '../shared/Tooltip'

interface FailedFileCardProps {
  id: string
  filename: string
  error: string
}

const FailedFileCard: React.FC<FailedFileCardProps> = ({
  id,
  filename,
  error = 'Analysis failed.',
}) => {
  return (
    <div
      key={id}
      className="relative p-3 border border-brd-danger-secondary bg-bgCol-default-default rounded-lg group min-w-64"
    >
      <div className="flex">
        <div className="flex-shrink-0 p-2 bg-bgCol-danger-tertiary rounded">
          <FaildUploadIcon />
        </div>
        <div className="ml-2 flex-1">
          <Tooltip tooltipContents={filename} position="above">
            <p className="text-sm font-medium truncate w-40">{filename}</p>
          </Tooltip>
          <p className="text-xs text-txt-danger-secondary">{error}</p>
        </div>
      </div>
    </div>
  )
}

export default observer(FailedFileCard)

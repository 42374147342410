import React, { useState } from 'react'

import DocumentDeleteForm from './DocumentDeleteForm'
import { scrollAndHighlightDocument } from '../../actions/activityItemActions'
import { downloadFile } from '../../apis/filesApi'
import { useStore } from '../../useStore'
import DownloadIcon from '../assets/DownloadIcon'
import FileIcon from '../assets/FileIcon'
import TrashIcon from '../assets/TrashIcon'
import Tooltip from '../shared/Tooltip'
import { TruncatedText } from '../TruncatedText'
import { IEnterpriseDoc } from '../types/interfaces'

interface DocumentProps {
  doc: IEnterpriseDoc
}

const EnterpriseDocument: React.FC<DocumentProps> = ({ doc }) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const store = useStore()
  const [isHovered, setIsHovered] = useState(false)

  return (
    <div
      className="group relative flex flex-col items-center text-center w-[10.625rem] pt-4 px-6 rounded-lg duration-300 cursor-pointer hover:bg-bgCol-disabled-default"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      ref={doc.ref}
      onClick={() => scrollAndHighlightDocument(store, doc)}
    >
      <FileIcon
        fileType={doc.documentable.content_type}
        isHovered={isHovered}
      />

      <div className="px-[0.5rem] py-[0.5rem] w-[9.625rem]">
        <TruncatedText
          text={doc.file_name}
          additionalClasses="w-[10rem]"
          textClasses="text-sm font-medium truncate w-full text-txt-default-default"
        />
        <TruncatedText
          text={doc.documentable.generated_name}
          additionalClasses="w-[10rem]"
          textClasses="text-sm font-medium truncate w-full text-txt-default-default"
        />
      </div>
      <div className="absolute right-2 top-2 flex items-center space-x-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
        <Tooltip tooltipContents="Download" position="left">
          <button onClick={() => downloadFile(store, doc)}>
            <DownloadIcon />
          </button>
        </Tooltip>
        <Tooltip tooltipContents="Delete" position="left">
          <button onClick={() => setIsDeleteModalOpen(true)}>
            <TrashIcon />
          </button>
        </Tooltip>
      </div>

      {isDeleteModalOpen && (
        <DocumentDeleteForm
          doc={doc}
          onClose={() => setIsDeleteModalOpen(false)}
        />
      )}
    </div>
  )
}

export default EnterpriseDocument

import PropTypes from 'prop-types'
import React from 'react'

const SelectInput = ({ value, onChange, options }) => {
  return (
    <div className="border rounded-md border-brd-default-default py-1">
      <select
        value={value}
        onChange={onChange}
        className="block w-full py-2 border-r-8 border-transparent px-3 text-sm focus:outline-none"
      >
        {Object.entries(options).map(([key, optionLabel]) => (
          <option key={key} value={key}>
            {optionLabel}
          </option>
        ))}
      </select>
    </div>
  )
}

SelectInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
}

export default SelectInput

{
  "matterDetails": {
    "label": "Matter details",
    "options": [
      { "value": "has_file_number", "label": "Has a file number" },
      { "value": "no_file_number", "label": "Has no file number" }
    ]
  },
  "lastModified": {
    "label": "Last modified",
    "options": [
      { "value": "today", "label": "Today" },
      { "value": "yesterday", "label": "Yesterday" },
      { "value": "last_7_days", "label": "Last 7 days" },
      { "value": "last_30_days", "label": "Last 30 days" },
      { "value": "last_12_months", "label": "Last 12 months" }
    ]
  },
  "dateCreated": {
    "label": "Date created",
    "options": [
      { "value": "today", "label": "Today" },
      { "value": "yesterday", "label": "Yesterday" },
      { "value": "last_7_days", "label": "Last 7 days" },
      { "value": "last_30_days", "label": "Last 30 days" },
      { "value": "last_12_months", "label": "Last 12 months" }
    ]
  }
}

import React from 'react'

const FingerprintIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      d="M6.8125 4.30106C7.65327 3.612 8.67237 3.17574 9.75133 3.04298C10.8302 2.91024 11.9246 3.08644 12.9074 3.55113C13.8901 4.01582 14.7208 4.7499 15.3028 5.66804C15.8848 6.58616 16.1943 7.65063 16.1952 8.7377C16.2121 12.202 14.8568 15.5322 12.4254 18"
      stroke="#1B212D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 10.479C3.22581 10.479 3.44939 10.4343 3.65789 10.3476C3.86638 10.2609 4.0557 10.1339 4.21493 9.9738C4.37417 9.81374 4.50019 9.62368 4.58576 9.41476C4.67132 9.2058 4.71475 8.98198 4.71352 8.75618C4.71361 8.07993 4.83275 7.40898 5.06549 6.77405"
      stroke="#1B212D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5131 15.8048C9.70733 16.6471 8.7686 17.3511 7.73438 17.8888"
      stroke="#1B212D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 13.3318C4.22826 13.3318 5.40621 12.8438 6.27472 11.9753C7.14322 11.1068 7.63114 9.92891 7.63114 8.70062C7.63114 7.93911 7.93365 7.20878 8.47216 6.6703C9.01057 6.13183 9.74091 5.82932 10.5025 5.82932C11.264 5.82932 11.9943 6.13183 12.5327 6.6703C13.0713 7.20878 13.3738 7.93911 13.3738 8.70062C13.3658 10.2836 12.9917 11.8432 12.2808 13.2577"
      stroke="#1B212D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.96875 16.1291C5.76937 15.9023 7.42414 15.0225 8.6192 13.6567C9.81432 12.2909 10.4666 10.534 10.4524 8.71924"
      stroke="#1B212D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default FingerprintIcon

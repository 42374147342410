import PropTypes from 'prop-types'
import React from 'react'

const CancelButton = ({ handleCancel }) => (
  <button
    className="px-4 py-2 text-txt-neutral-default bg-bgCol-neutral-default rounded-lg hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
    onClick={handleCancel}
  >
    Cancel
  </button>
)

CancelButton.propTypes = {
  handleCancel: PropTypes.func,
}

export default CancelButton

import React from 'react'

import CircularLoadingIcon from './CircularLoadingIcon'

const Loading = () => {
  return (
    <div className="flex justify-center items-center h-full">
      <CircularLoadingIcon size={48} />
    </div>
  )
}

export default Loading

import PropTypes from 'prop-types'
import React from 'react'

const SubmitButton = ({ handleSubmit, disabled }) => (
  <button
    className={`px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 ${
      disabled
        ? 'cursor-not-allowed bg-bgCol-disabled-default text-txt-disabled-default'
        : 'bg-bgCol-brand-default text-txt-utilities-default hover:bg-blue-700'
    }`}
    onClick={handleSubmit}
    disabled={disabled}
  >
    Submit
  </button>
)

SubmitButton.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default SubmitButton

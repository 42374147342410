import PropTypes from 'prop-types'
import React, { useState } from 'react'

import MatterDetailsForm from './shared/MatterDetailsForm'

const SettingsModal = ({ matter, onClose }) => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(true)

  const handleClose = () => {
    setIsSettingsOpen(false)
    onClose()
  }

  return (
    <>
      {isSettingsOpen && (
        <MatterDetailsForm matter={matter} onClose={handleClose} />
      )}
    </>
  )
}

SettingsModal.propTypes = {
  matter: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default SettingsModal

import PropTypes from 'prop-types'
import React from 'react'

const PlaneIcon = ({ fill, stroke, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    stroke={stroke}
    {...props}
  >
    <path
      d="M10.6122 12.0509H4.80296M4.71354 12.3588L2.24734 19.7257C2.05359 20.3045 1.95672 20.5938 2.02624 20.772C2.08661 20.9268 2.21628 21.0441 2.37628 21.0888C2.56052 21.1402 2.8388 21.015 3.39536 20.7645L21.0465 12.8215C21.5897 12.577 21.8613 12.4548 21.9453 12.285C22.0182 12.1375 22.0182 11.9644 21.9453 11.8169C21.8613 11.6471 21.5897 11.5249 21.0465 11.2804L3.38921 3.33467C2.83433 3.08497 2.55688 2.96012 2.37282 3.01134C2.21297 3.05581 2.08332 3.17283 2.02274 3.32729C1.95298 3.50516 2.04882 3.79391 2.2405 4.3714L4.71423 11.8244C4.74715 11.9236 4.76361 11.9732 4.77011 12.0239C4.77588 12.0689 4.77582 12.1145 4.76994 12.1595C4.76331 12.2102 4.74672 12.2597 4.71354 12.3588Z"
      strokeWidth="2"
    />
  </svg>
)

PlaneIcon.propTypes = {
  fill: PropTypes.string,
  stroke: PropTypes.string,
}

export default PlaneIcon

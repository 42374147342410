import React from 'react'

import EmptyDocumentDefault from '../assets/EmptyDocumentDefault'
import EmptyDocumentPDF from '../assets/EmptyDocumentPDF'

const EmptyActivitiesPlaceholder = () => {
  return (
    <>
      <div className="mt-8 mb-4 text-txt-default-secondary text-center">
        <p>Quickly access the documents you upload and generate here.</p>
      </div>
      <div className="flex flex-row gap-4">
        <div className="bg-bgCol-neutral-default rounded-lg p-3">
          <EmptyDocumentDefault width="100" height="100" />
        </div>
        <div className="bg-bgCol-neutral-default rounded-lg p-3">
          <EmptyDocumentPDF width="100" height="100" />
        </div>
      </div>
    </>
  )
}

export default EmptyActivitiesPlaceholder

import PropTypes from 'prop-types'
import React from 'react'

const WorkflowUploadIcon = ({ stroke = '#D87E03' }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 12L12 4M12 4L4 12M12 4V20"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

WorkflowUploadIcon.propTypes = {
  stroke: PropTypes.string,
}

export default WorkflowUploadIcon

import PropTypes from 'prop-types'
import React from 'react'

const SortIcon = ({ stroke = '#1B212D' }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 7L5 3M5 3L9 7M5 3V17M19 13L15 17M15 17L11 13M15 17V3"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

SortIcon.propTypes = {
  stroke: PropTypes.string,
}

export default SortIcon

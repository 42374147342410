export const FILE_TYPES = {
  PDF: {
    mimeType: 'application/pdf',
    icon: 'pdf.jpg',
  },
  DOCX: {
    mimeType:
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    icon: 'doc.jpg',
  },
  DOC: {
    mimeType: 'application/msword',
    icon: 'doc.jpg',
  },
  PPTX: {
    mimeType:
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    icon: 'ppt.jpg',
  },
  PPT: {
    mimeType: 'application/vnd.ms-powerpoint',
    icon: 'ppt.jpg',
  },
  JPG: {
    mimeType: 'image/jpeg',
    icon: 'image.jpg',
  },
  PNG: {
    mimeType: 'image/png',
    icon: 'image.jpg',
  },
}

import React from 'react'

const EmptyDocumentPDF = () => (
  <svg
    width="130"
    height="176"
    viewBox="0 0 146 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <filter
        id="filter0_d_3104_1338"
        x="0"
        y="0"
        width="146"
        height="200"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.611765 0 0 0 0 0.611765 0 0 0 0 0.611765 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3104_1338"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_3104_1338"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_3104_1338"
        x="0"
        y="136"
        width="146"
        height="64"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.611765 0 0 0 0 0.611765 0 0 0 0 0.611765 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3104_1338"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_3104_1338"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_3104_1338">
        <rect x="8" y="6" width="130" height="184" rx="8" fill="white" />
      </clipPath>
    </defs>
    <g filter="url(#filter0_d_3104_1338)">
      <g clipPath="url(#clip0_3104_1338)">
        <rect x="8" y="6" width="130" height="184" rx="8" fill="white" />
        <g filter="url(#filter1_d_3104_1338)">
          <rect
            width="130"
            height="48"
            transform="translate(8 142)"
            fill="#F2F2F2"
          />
          <rect x="20" y="154" width="106" height="8" rx="4" fill="white" />
          <rect x="43" y="170" width="60" height="8" rx="4" fill="white" />
        </g>
        <path
          d="M99.6671 124H44.1116C41.1647 124 37.0837 124.085 35 122.001C32.9163 119.917 33.0005 115.836 33.0005 112.889V35.1116C33.0005 32.1647 32.9163 28.0837 35 26C37.0837 23.9163 41.1647 24.0005 44.1116 24.0005H83.0005L110.778 51.7782V112.889C110.778 115.836 110.862 119.917 108.779 122.001C106.695 124.085 102.614 124 99.6671 124Z"
          fill="#FAFAFA"
          stroke="#F2F2F2"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M83 24V46.2222C83 47.6956 83.5853 49.1087 84.6272 50.1506C85.6691 51.1925 87.0821 51.7778 88.5556 51.7778H110.778"
          stroke="#F2F2F2"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g opacity="0.6">
          <path
            d="M64.9656 81.48C64.9656 82.2933 64.779 83.04 64.4056 83.72C64.0323 84.3866 63.459 84.9266 62.6856 85.34C61.9123 85.7533 60.9523 85.96 59.8056 85.96H57.6856V91H54.2656V76.96H59.8056C60.9256 76.96 61.8723 77.1533 62.6456 77.54C63.419 77.9266 63.999 78.46 64.3856 79.14C64.7723 79.82 64.9656 80.6 64.9656 81.48ZM59.5456 83.24C60.199 83.24 60.6856 83.0866 61.0056 82.78C61.3256 82.4733 61.4856 82.04 61.4856 81.48C61.4856 80.92 61.3256 80.4866 61.0056 80.18C60.6856 79.8733 60.199 79.72 59.5456 79.72H57.6856V83.24H59.5456Z"
            fill="#CECECE"
          />
          <path
            d="M72.0061 76.96C73.4861 76.96 74.7794 77.2533 75.8861 77.84C76.9928 78.4266 77.8461 79.2533 78.4461 80.32C79.0594 81.3733 79.3661 82.5933 79.3661 83.98C79.3661 85.3533 79.0594 86.5733 78.4461 87.64C77.8461 88.7066 76.9861 89.5333 75.8661 90.12C74.7594 90.7066 73.4728 91 72.0061 91H66.7461V76.96H72.0061ZM71.7861 88.04C73.0794 88.04 74.0861 87.6866 74.8061 86.98C75.5261 86.2733 75.8861 85.2733 75.8861 83.98C75.8861 82.6866 75.5261 81.68 74.8061 80.96C74.0861 80.24 73.0794 79.88 71.7861 79.88H70.1661V88.04H71.7861Z"
            fill="#CECECE"
          />
          <path
            d="M90.4173 76.96V79.7H84.6973V82.66H88.9773V85.32H84.6973V91H81.2773V76.96H90.4173Z"
            fill="#CECECE"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default EmptyDocumentPDF

import React from 'react'

import { rollbarConfig } from '../rollbarConfig'
import { useStore } from '../useStore'

interface ErrorPageProps {
  error: Error
  onRetry: () => void
}

const ErrorPage = ({ error, onRetry }: ErrorPageProps) => {
  const store = useStore()

  console.error('Application error:', error)
  rollbarConfig(store)?.error('Application error', error)

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="text-center p-8 bg-white rounded-lg shadow-md">
        <h1 className="text-2xl font-bold mb-4">Oops! Something went wrong</h1>
        <p className="mb-4">
          We&apos;re sorry, but we encountered an error while loading the
          application.
        </p>
        <p className="mb-4">
          Please try refreshing the page if the problem persists.
        </p>
        <button
          onClick={onRetry}
          className="px-4 py-2 bg-blue-500 text-white rounded"
        >
          Retry
        </button>
      </div>
    </div>
  )
}

export default ErrorPage

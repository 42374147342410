import PropTypes from 'prop-types'
import React from 'react'

const XIcon = ({ classNames }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    className={classNames || ''}
  >
    <path
      d="M14 2L2 14M2 2L14 14"
      stroke="#454548"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

XIcon.propTypes = {
  classNames: PropTypes.string,
}

export default XIcon

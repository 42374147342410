import React from 'react'

const HomeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      d="M7.64509 18H5.2709C4.51529 18 3.79063 17.6998 3.25634 17.1655C2.72204 16.6312 2.42188 15.9065 2.42188 15.1509V7.55353L8.11993 3.75482"
      stroke="#1B212D"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M10.9688 3.75482L16.6668 7.55353V15.1509C16.6668 15.9065 16.3666 16.6312 15.8323 17.1655C15.298 17.6998 14.5733 18 13.8178 18H11.4436"
      stroke="#1B212D"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M18.0942 8.50322L16.6697 7.55354L10.9716 3.75484L10.0694 3.15654C9.91432 3.05443 9.73274 3 9.54708 3C9.36142 3 9.17984 3.05443 9.02476 3.15654L8.12257 3.75484L2.42451 7.55354L1 8.50322"
      stroke="#1B212D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.4393 18V14.2013C11.4393 13.6976 11.2392 13.2145 10.883 12.8582C10.5268 12.502 10.0437 12.3019 9.53998 12.3019C9.03627 12.3019 8.55317 12.502 8.19695 12.8582C7.84072 13.2145 7.64062 13.6976 7.64062 14.2013V18"
      stroke="#1B212D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default HomeIcon

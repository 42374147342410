import PropTypes from 'prop-types'
import React from 'react'

const FolderIcon = ({ stroke, backgroundColor, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M2 5.22222C2 4.63285 2.23413 4.06762 2.65087 3.65087C3.06762 3.23413 3.63285 3 4.22222 3H8.58889C8.95465 2.99995 9.31477 3.09017 9.6373 3.26268C9.95982 3.43518 10.2348 3.68463 10.4378 3.98889L11.34 5.34444C11.543 5.6487 11.818 5.89815 12.1405 6.07066C12.463 6.24316 12.8231 6.33339 13.1889 6.33333H19.7778C20.3671 6.33333 20.9324 6.56746 21.3491 6.98421C21.7659 7.40095 22 7.96619 22 8.55556V18.5556C22 19.1449 21.7659 19.7102 21.3491 20.1269C20.9324 20.5436 20.3671 20.7778 19.7778 20.7778H4.22222C3.63285 20.7778 3.06762 20.5436 2.65087 20.1269C2.23413 19.7102 2 19.1449 2 18.5556V5.22222Z"
      stroke={stroke}
      fill={backgroundColor}
    />
  </svg>
)

FolderIcon.propTypes = {
  stroke: PropTypes.string,
  backgroundColor: PropTypes.string,
}

export default FolderIcon

import { action } from 'mobx'

import consumer from './consumer'

const handleMatterUpdate = action((store, message) => {
  const matter = store.matters.find((m) => m.id === message.matter.id)

  // Two possible places where a matter to update can be found: the list of matters driving the left nav bar, and the selected matter.

  if (matter) {
    Object.assign(matter, message.matter)
  }

  if (store?.selectedMatter?.id === message.matter.id) {
    Object.assign(store.selectedMatter, message.matter)
  }
})

// A channel for all updates relevant to the current user, no matter what state the client app is in
const connectToUserChannel = (store) => {
  store.userChannel = consumer.subscriptions.create(
    { channel: 'UserChannel' },
    {
      received(data) {
        const parsedChunk = JSON.parse(data)

        switch (parsedChunk.message_type) {
          case 'matter_update':
            handleMatterUpdate(store, parsedChunk.message)
            break
        }
      },
    }
  )
}

export { connectToUserChannel }

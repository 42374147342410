import { action } from 'mobx'

// Matter channels
export const getMatterChanel = (store, matterId) => {
  return store.matterChannels[matterId]
}

export const setMatterChannel = action((store, matterId, channel) => {
  store.matterChannels[matterId] = channel
})

export const deleteMatterChannel = action((store, matterId) => {
  delete store.matterChannels[matterId]
})

// Workflow Activity channels
export const setActivityChannel = (store, matterId, channel) => {
  store.activityChannels = store.activityChannels || {}
  store.activityChannels[matterId] = channel
}

export const deleteActivityChannel = (store, matterId) => {
  if (store.activityChannels && store.activityChannels[matterId]) {
    delete store.activityChannels[matterId]
  }
}

export const getActivityChannel = (store, matterId) => {
  return store.activityChannels ? store.activityChannels[matterId] : null
}

// Document channels
export const setDocumentChannel = (store, matterId, channel) => {
  store.documentChannels = store.documentChannels || {}
  store.documentChannels[matterId] = channel
}

export const deleteDocumentChannel = (store, matterId) => {
  if (store.documentChannels && store.documentChannels[matterId]) {
    delete store.documentChannels[matterId]
  }
}

export const getDocumentChannel = (store, matterId) => {
  return store.documentChannels ? store.documentChannels[matterId] : null
}

import { observer } from 'mobx-react-lite'
import React from 'react'

interface ITabButton {
  isActive: boolean
  onClick: () => void
  children: React.ReactNode
}

interface ITabContent {
  activeTab: string
  tabs: Array<{ key: string; component: JSX.Element }>
}

interface IWorkflowBase {
  title: string
  tabs: Array<{ key: string; label: string; component: JSX.Element }>
  activeTab: string
  onTabChange: (key: string) => void
}

const TabButton = ({ isActive, onClick, children }: ITabButton) => (
  <button
    className={`w-6/12 text-sm font-medium p-2 ${isActive ? 'text-txt-brand-default border-b-2 border-txt-brand-default' : ''}`}
    onClick={onClick}
  >
    {children}
  </button>
)

const TabContent = observer(({ activeTab, tabs }: ITabContent) => {
  const ActiveComponent =
    tabs.find((tab) => tab.key === activeTab)?.component || null
  return ActiveComponent ? ActiveComponent : null
})

export const WorkflowBase = observer(
  ({ title, tabs, activeTab, onTabChange }: IWorkflowBase) => (
    <div className="w-[23rem] bg-white border-l flex flex-col">
      <div className="px-4 pb-4 sticky">
        <div className="text-lg py-5 pb-2 font-semibold">{title}</div>
        <div className="my-2 flex">
          {tabs.map((tab) => (
            <TabButton
              key={tab.key}
              isActive={activeTab === tab.key}
              onClick={() => onTabChange(tab.key)}
            >
              {tab.label}
            </TabButton>
          ))}
        </div>
      </div>

      <div className="flex-1 overflow-y-auto overflow-x-clip ml-4">
        <TabContent activeTab={activeTab} tabs={tabs} />
      </div>
    </div>
  )
)

import PropTypes from 'prop-types'
import React from 'react'

function SummaryDocPopoverAiTooltip({ scrollToInfoBox }) {
  return (
    <div className="w-[22rem] text-left">
      <p className="pb-2">
        This summary document was created using generative AI
      </p>
      <a
        className="text-txt-brand-secondary font-medium"
        href="#"
        onClick={scrollToInfoBox}
      >
        Read more
      </a>
    </div>
  )
}

export default SummaryDocPopoverAiTooltip

SummaryDocPopoverAiTooltip.propTypes = {
  scrollToInfoBox: PropTypes.func.isRequired,
}

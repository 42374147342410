import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { fetchStates } from '../../../apis/fetchStatesApi'
import { useStore } from '../../../useStore'

const CanadianDropdown = ({
  state,
  setJurisdiction,
  setState,
  additionalContext,
}) => {
  const [states, setStates] = useState([])
  const store = useStore()

  const normalizeValue = (value) => {
    return value === 'none' || value === undefined || value === 'null'
      ? ''
      : value
  }

  useEffect(() => {
    const loadStates = async () => {
      try {
        const data = await fetchStates(store, 'CA')
        setStates(data)
      } catch (error) {
        console.error('Error fetching states:', error)
      }
    }

    const setDefaultValues = () => {
      setJurisdiction(null)

      const normalizedJurisdiction = normalizeValue(
        additionalContext?.jurisdiction
      )
      const normalizedState = normalizeValue(additionalContext?.state)
      const normalizedUserState = normalizeValue(store.user.enterprise.state)

      // set the value from AIE
      if (normalizedJurisdiction === 'Federal') {
        setState('Federal')
      } else if (normalizedJurisdiction === 'State' && normalizedState !== '') {
        setState(normalizedState)
      } else if (normalizedUserState !== '') {
        // set the value from User address
        setState(normalizedUserState)
      } else {
        // set the first value in dropdown
        setState(states[0]?.name)
      }
    }

    loadStates()
    setDefaultValues()
  }, [])

  return (
    <div>
      {/* Jurisdiction Dropdown */}
      <div className="w-full block">
        <label className="text-sm font-medium text-gray-700 mb-2">
          Jurisdiction
        </label>
        <div className="w-full border rounded-md border-brd-default-default py-1">
          <select
            className="w-full py-2 border-r-8 border-transparent px-3 text-sm focus:outline-none"
            value={state}
            onChange={(e) => {
              setState(e.target.value)
              setJurisdiction(null)
            }}
          >
            {states.map((state) => (
              <option key={state.id} value={state.name}>
                {state.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  )
}

CanadianDropdown.propTypes = {
  setState: PropTypes.func.isRequired,
  state: PropTypes.string.isRequired,
  setJurisdiction: PropTypes.func.isRequired,
  additionalContext: PropTypes.shape({
    jurisdiction: PropTypes.string,
    state: PropTypes.string,
  }).isRequired,
}

export default CanadianDropdown

function storeMessageObject(key, data) {
  localStorage.setItem(key, JSON.stringify(data))
}

function getMessageObject(key) {
  const itemValue = localStorage.getItem(key)

  if (itemValue === null) {
    return ''
  }

  return JSON.parse(itemValue)
}

function removeStoredMessage(key) {
  localStorage.removeItem(key)
}

// for debug purposes only
function getAllLocalStorageItems() {
  const allItems = {}
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i)
    allItems[key] = localStorage.getItem(key)
  }
  return allItems
}

export {
  storeMessageObject,
  getMessageObject,
  removeStoredMessage,
  getAllLocalStorageItems,
}

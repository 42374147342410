import PropTypes from 'prop-types'
import React from 'react'

const IconButton = ({
  icon: Icon,
  onClick = () => {},
  fill = 'none',
  stroke = 'white',
  id,
  className,
}) => {
  return (
    <button
      className={`flex w-[32px] h-[32px] justify-center items-center gap-[8px] rounded-[4px] ${className}`}
      onClick={onClick}
      id={id}
    >
      <Icon width={16} height={16} fill={fill} stroke={stroke} />
    </button>
  )
}

IconButton.propTypes = {
  icon: PropTypes.elementType.isRequired,
  onClick: PropTypes.func,
  bgColor: PropTypes.string,
  borderColor: PropTypes.string,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
}

export default IconButton

import { action } from 'mobx'

const addFile = action((store, fileData, status = 'uploading') => {
  store.uploadedFiles.push({
    file: fileData.file,
    filename: fileData.file_name,
    progress: 0, // Initial progress
    status: status,
    content_type: fileData.content_type,
    icon_for_content_type: fileData.icon_for_content_type,
    matterId: store.selectedMatter?.id,
    e_attachment_id: fileData.e_attachment_id,
    error_msg: fileData.error_msg || null,
  })
})

export default addFile

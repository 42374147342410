import React from 'react'

const SearchIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="48"
    viewBox="0 0 22 22"
    fill="#5133EA"
  >
    <mask
      id="mask0_1507_16854"
      className="mask-type:luminance"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="22"
      height="22"
    >
      <path
        d="M10.0168 19.0337C14.9968 19.0337 19.0337 14.9968 19.0337 10.0168C19.0337 5.0369 14.9968 1 10.0168 1C5.0369 1 1 5.0369 1 10.0168C1 14.9968 5.0369 19.0337 10.0168 19.0337Z"
        fill="#555555"
        stroke="white"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M13.0176 6.48639C12.624 6.09179 12.1563 5.77886 11.6414 5.56559C11.1264 5.35232 10.5744 5.24291 10.0171 5.24366C9.45975 5.24291 8.90775 5.35232 8.39283 5.56559C7.8779 5.77886 7.4102 6.09179 7.0166 6.48639M16.4997 16.4999L21.0001 21.0003"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </mask>
    <g mask="url(#mask0_1507_16854)">
      <path
        d="M-1.12109 -1.12158H24.3382V24.3378H-1.12109V-1.12158Z"
        fill="#5133EA"
      />
    </g>
  </svg>
)

export default SearchIcon

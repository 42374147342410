import React from 'react'

const EmptyDocumentDefault = () => (
  <svg
    width="130"
    height="176"
    viewBox="0 0 146 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <filter
        id="filter0_d_3104_1327"
        x="0"
        y="0"
        width="146"
        height="200"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.611765 0 0 0 0 0.611765 0 0 0 0 0.611765 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3104_1327"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_3104_1327"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_3104_1327"
        x="0"
        y="136"
        width="146"
        height="64"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.611765 0 0 0 0 0.611765 0 0 0 0 0.611765 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3104_1327"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_3104_1327"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_3104_1327">
        <rect x="8" y="6" width="130" height="184" rx="8" fill="white" />
      </clipPath>
    </defs>
    <g filter="url(#filter0_d_3104_1327)">
      <g clipPath="url(#clip0_3104_1327)">
        <rect x="8" y="6" width="130" height="184" rx="8" fill="white" />
        <g filter="url(#filter1_d_3104_1327)">
          <rect
            width="130"
            height="48"
            transform="translate(8 142)"
            fill="#F2F2F2"
          />
          <rect x="20" y="154" width="106" height="8" rx="4" fill="white" />
          <rect x="43" y="170" width="60" height="8" rx="4" fill="white" />
        </g>
        <path
          d="M99.6671 124H44.1116C41.1647 124 37.0837 124.085 35 122.001C32.9163 119.917 33.0005 115.836 33.0005 112.889V35.1116C33.0005 32.1647 32.9163 28.0837 35 26C37.0837 23.9163 41.1647 24.0005 44.1116 24.0005H83.0005L110.778 51.7782V112.889C110.778 115.836 110.862 119.917 108.779 122.001C106.695 124.085 102.614 124 99.6671 124Z"
          fill="#FAFAFA"
          stroke="#F2F2F2"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M83 24V46.2222C83 47.6956 83.5853 49.1087 84.6272 50.1506C85.6691 51.1925 87.0821 51.7778 88.5556 51.7778H110.778"
          stroke="#F2F2F2"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M73.6051 91.0363C73.5369 90.772 73.3992 90.5309 73.2062 90.3379C73.0132 90.1449 72.7721 90.0072 72.5078 89.939L67.823 88.7309C67.7431 88.7083 67.6727 88.6601 67.6226 88.5938C67.5726 88.5275 67.5455 88.4467 67.5455 88.3636C67.5455 88.2806 67.5726 88.1997 67.6226 88.1334C67.6727 88.0672 67.7431 88.019 67.823 87.9963L72.5078 86.7875C72.772 86.7194 73.0131 86.5818 73.206 86.389C73.399 86.1961 73.5368 85.9551 73.6051 85.691L74.8132 81.0062C74.8356 80.9259 74.8837 80.8552 74.9501 80.8049C75.0165 80.7545 75.0975 80.7273 75.1808 80.7273C75.2642 80.7273 75.3452 80.7545 75.4116 80.8049C75.478 80.8552 75.5261 80.9259 75.5485 81.0062L76.7558 85.691C76.824 85.9552 76.9617 86.1964 77.1547 86.3894C77.3477 86.5824 77.5888 86.7201 77.8531 86.7883L82.5379 87.9956C82.6185 88.0178 82.6895 88.0658 82.7401 88.1323C82.7908 88.1988 82.8182 88.2801 82.8182 88.3636C82.8182 88.4472 82.7908 88.5285 82.7401 88.595C82.6895 88.6614 82.6185 88.7095 82.5379 88.7317L77.8531 89.939C77.5888 90.0072 77.3477 90.1449 77.1547 90.3379C76.9617 90.5309 76.824 90.772 76.7558 91.0363L75.5478 95.7211C75.5253 95.8013 75.4772 95.872 75.4108 95.9224C75.3444 95.9727 75.2634 96 75.1801 96C75.0967 96 75.0157 95.9727 74.9493 95.9224C74.8829 95.872 74.8348 95.8013 74.8124 95.7211L73.6051 91.0363Z"
          fill="#CECECE"
        />
        <path
          d="M64.4627 77.8909C64.4237 77.7399 64.345 77.6021 64.2347 77.4918C64.1244 77.3815 63.9866 77.3028 63.8356 77.2638L61.1586 76.5735C61.1129 76.5606 61.0727 76.5331 61.0441 76.4952C61.0155 76.4573 61 76.4111 61 76.3636C61 76.3162 61.0155 76.27 61.0441 76.2321C61.0727 76.1942 61.1129 76.1667 61.1586 76.1538L63.8356 75.463C63.9866 75.4241 64.1244 75.3454 64.2346 75.2352C64.3449 75.1251 64.4236 74.9873 64.4627 74.8364L65.153 72.1594C65.1658 72.1135 65.1933 72.0731 65.2312 72.0444C65.2692 72.0156 65.3155 72 65.3631 72C65.4107 72 65.457 72.0156 65.4949 72.0444C65.5329 72.0731 65.5603 72.1135 65.5732 72.1594L66.2631 74.8364C66.302 74.9874 66.3807 75.1252 66.491 75.2355C66.6013 75.3458 66.7391 75.4245 66.8901 75.4634L69.5671 76.1533C69.6131 76.166 69.6537 76.1935 69.6827 76.2315C69.7116 76.2694 69.7273 76.3159 69.7273 76.3636C69.7273 76.4114 69.7116 76.4578 69.6827 76.4958C69.6537 76.5338 69.6131 76.5613 69.5671 76.574L66.8901 77.2638C66.7391 77.3028 66.6013 77.3815 66.491 77.4918C66.3807 77.6021 66.302 77.7399 66.2631 77.8909L65.5727 80.5679C65.5599 80.6137 65.5324 80.6541 65.4945 80.6829C65.4566 80.7117 65.4103 80.7273 65.3626 80.7273C65.315 80.7273 65.2687 80.7117 65.2308 80.6829C65.1928 80.6541 65.1654 80.6137 65.1525 80.5679L64.4627 77.8909Z"
          fill="#CECECE"
        />
        <path
          d="M73.6051 91.0363C73.5369 90.772 73.3992 90.5309 73.2062 90.3379C73.0132 90.1449 72.7721 90.0072 72.5078 89.939L67.823 88.7309C67.7431 88.7083 67.6727 88.6601 67.6226 88.5938C67.5726 88.5275 67.5455 88.4467 67.5455 88.3636C67.5455 88.2806 67.5726 88.1997 67.6226 88.1334C67.6727 88.0672 67.7431 88.019 67.823 87.9963L72.5078 86.7875C72.772 86.7194 73.0131 86.5818 73.206 86.389C73.399 86.1961 73.5368 85.9551 73.6051 85.691L74.8132 81.0062C74.8356 80.9259 74.8837 80.8552 74.9501 80.8049C75.0165 80.7545 75.0975 80.7273 75.1808 80.7273C75.2642 80.7273 75.3452 80.7545 75.4116 80.8049C75.478 80.8552 75.5261 80.9259 75.5485 81.0062L76.7558 85.691C76.824 85.9552 76.9617 86.1964 77.1547 86.3894C77.3477 86.5824 77.5888 86.7201 77.8531 86.7883L82.5379 87.9956C82.6185 88.0178 82.6895 88.0658 82.7401 88.1323C82.7908 88.1988 82.8182 88.2801 82.8182 88.3636C82.8182 88.4472 82.7908 88.5285 82.7401 88.595C82.6895 88.6614 82.6185 88.7095 82.5379 88.7317L77.8531 89.939C77.5888 90.0072 77.3477 90.1449 77.1547 90.3379C76.9617 90.5309 76.824 90.772 76.7558 91.0363L75.5478 95.7211C75.5253 95.8013 75.4772 95.872 75.4108 95.9224C75.3444 95.9727 75.2634 96 75.1801 96C75.0967 96 75.0157 95.9727 74.9493 95.9224C74.8829 95.872 74.8348 95.8013 74.8124 95.7211L73.6051 91.0363Z"
          stroke="#CECECE"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M64.4627 77.8909C64.4237 77.7399 64.345 77.6021 64.2347 77.4918C64.1244 77.3815 63.9866 77.3028 63.8356 77.2638L61.1586 76.5735C61.1129 76.5606 61.0727 76.5331 61.0441 76.4952C61.0155 76.4573 61 76.4111 61 76.3636C61 76.3162 61.0155 76.27 61.0441 76.2321C61.0727 76.1942 61.1129 76.1667 61.1586 76.1538L63.8356 75.463C63.9866 75.4241 64.1244 75.3454 64.2346 75.2352C64.3449 75.1251 64.4236 74.9873 64.4627 74.8364L65.153 72.1594C65.1658 72.1135 65.1933 72.0731 65.2312 72.0444C65.2692 72.0156 65.3155 72 65.3631 72C65.4107 72 65.457 72.0156 65.4949 72.0444C65.5329 72.0731 65.5603 72.1135 65.5732 72.1594L66.2631 74.8364C66.302 74.9874 66.3807 75.1252 66.491 75.2355C66.6013 75.3458 66.7391 75.4245 66.8901 75.4634L69.5671 76.1533C69.6131 76.166 69.6537 76.1935 69.6827 76.2315C69.7116 76.2694 69.7273 76.3159 69.7273 76.3636C69.7273 76.4114 69.7116 76.4578 69.6827 76.4958C69.6537 76.5338 69.6131 76.5613 69.5671 76.574L66.8901 77.2638C66.7391 77.3028 66.6013 77.3815 66.491 77.4918C66.3807 77.6021 66.302 77.7399 66.2631 77.8909L65.5727 80.5679C65.5599 80.6137 65.5324 80.6541 65.4945 80.6829C65.4566 80.7117 65.4103 80.7273 65.3626 80.7273C65.315 80.7273 65.2687 80.7117 65.2308 80.6829C65.1928 80.6541 65.1654 80.6137 65.1525 80.5679L64.4627 77.8909Z"
          stroke="#CECECE"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
)

export default EmptyDocumentDefault

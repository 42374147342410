import React from 'react'

const BellIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      d="M15.8309 15.0833C15.8351 15.3817 15.7324 15.6717 15.5413 15.901C15.3503 16.1302 15.0836 16.2837 14.7893 16.3333H14.5809H5.4143H5.20597C4.91171 16.2837 4.64495 16.1302 4.4539 15.901C4.26285 15.6717 4.16013 15.3817 4.1643 15.0833C4.1584 14.7845 4.2605 14.4936 4.45185 14.2639C4.6432 14.0343 4.91096 13.8814 5.20597 13.8333V9.04167C5.20597 7.77084 5.7108 6.55206 6.60941 5.65345C7.50803 4.75483 8.72685 4.25 9.9976 4.25C11.2684 4.25 12.4873 4.75483 13.3858 5.65345C14.2844 6.55206 14.7893 7.77084 14.7893 9.04167V13.8333C15.0843 13.8814 15.3521 14.0343 15.5434 14.2639C15.7348 14.4936 15.8368 14.7845 15.8309 15.0833Z"
      stroke="#1B212D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.32812 18H11.6615"
      stroke="#1B212D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6807 4.30833C10.778 4.169 10.8303 4.00326 10.8307 3.83333C10.8307 3.61232 10.7429 3.40036 10.5866 3.24407C10.4304 3.0878 10.2184 3 9.9974 3C9.7764 3 9.5644 3.0878 9.40815 3.24407C9.2519 3.40036 9.16406 3.61232 9.16406 3.83333C9.16448 4.00326 9.21681 4.169 9.31406 4.30833"
      stroke="#1B212D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default BellIcon

import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'

import AlexiDocument from './AlexiDocument'
import EmptyDocumentsPlaceholder from './EmptyDocumentsPlaceholder'
import EnterpriseDocument from './EnterpriseDocument'
import { setFetchedDocuments } from '../../actions/fileActions'
import { useStore } from '../../useStore'
import Loading from '../assets/Loading'

const DocumentsColumn = observer(() => {
  const store = useStore()
  const docs = store.documents
  const [documentsLoading, setDocumentsLoading] = React.useState(true)

  useEffect(() => {
    setDocumentsLoading(true)

    setFetchedDocuments(store).then(() => {
      setDocumentsLoading(false)
    })
    //   eslint-disable-next-line react-hooks/exhaustive-deps -- CORRECT DEPS
  }, [store.selectedMatter?.id])

  if (documentsLoading) {
    return <Loading />
  }

  return Array.isArray(docs) && docs.length > 0 ? (
    <div className="h-full overflow-y-auto">
      <div className="grid grid-cols-2 gap-2 p-1 pb-6">
        {docs.map((doc, index) => {
          switch (doc.documentable_type) {
            case 'InstantMemo':
            case 'SummaryDocument':
              return <AlexiDocument key={index} doc={doc} />
            case 'EnterpriseAttachment':
              return <EnterpriseDocument key={index} doc={doc} />
            default:
              return null
          }
        })}
      </div>
    </div>
  ) : (
    <EmptyDocumentsPlaceholder />
  )
})

export default DocumentsColumn

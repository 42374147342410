import PropTypes from 'prop-types'
import React, { useRef, useEffect, useState } from 'react'

import Tooltip from './shared/Tooltip'

const useIsTextTruncated = (text) => {
  const textRef = useRef(null)
  const [isTruncated, setIsTruncated] = useState(false)

  useEffect(() => {
    const checkTruncation = () => {
      if (textRef.current) {
        setIsTruncated(
          textRef.current.scrollWidth > textRef.current.clientWidth
        )
      }
    }

    checkTruncation()
    window.addEventListener('resize', checkTruncation)

    return () => window.removeEventListener('resize', checkTruncation)
  }, [text])

  return [textRef, isTruncated]
}

export const TruncatedText = ({
  text,
  tooltipPosition = 'below',
  additionalClasses = 'w-full',
  textClasses = 'text-txt-default-secondary',
}) => {
  const [textRef, isTruncated] = useIsTextTruncated(text) // Pass text to the hook

  const textContent = (
    <p
      ref={textRef}
      className={`${textClasses} text-sm font-normal leading-tight truncate w-[290px]`}
    >
      {text}
    </p>
  )

  return isTruncated ? (
    <Tooltip
      tooltipContents={text}
      position={tooltipPosition}
      additionalClasses={additionalClasses}
    >
      {textContent}
    </Tooltip>
  ) : (
    textContent
  )
}

TruncatedText.propTypes = {
  text: PropTypes.string.isRequired,
  tooltipPosition: PropTypes.string,
  additionalClasses: PropTypes.string,
  textClasses: PropTypes.string,
}
